const terms = [
    //terms service
    { type: 'secTitle', text: '01 AGB'},
    { type: 'catTitle', text: 'Präambel Geschäftsbedingungen'},
    { type: 'subTitle', text: 'Geltungsbereich'},
    { type: 'textTitl', text: "1.1 Mit Nutzung der Seite und den Services der feelix GmbH erklärst Du Dich damit einverstanden, ihr Angebot nur im Einklang mit diesen Geschäftsbedingungen zu nutzen. Wenn Du mit diesen Geschäftsbedingungen nicht einverstanden bist, darfst Du dieses Angebot nicht nutzen."},
    { type: 'textTitl', text: "1.2 Die nachstehenden Allgemeinen Nutzungs- und Geschäftsbedingungen (nachfolgend 'AGB' genannt) gelten ausschließlich für alle über die feelix GmbH und vom Kunden (nachfolgend “Verbraucher” genannt) abgeschlossenen Finanzverträge wie auch für sämtliche von der feelix GmbH zur Verfügung gestellten Leistungen, die online (z.B. im Internet) oder offline (z.B. per Telefon) angeboten bzw. zur Verfügung gestellt werden und von Dritten (nachfolgend 'Verbraucher' genannt) angenommen bzw. genutzt werden. Ferner gelten sie für alle sonstigen vertraglichen und vertragsähnlichen Beziehungen, die zwischen der feelix GmbH und dem Verbraucher bestehen.",},
    { type: 'textTitl', text: "1.3 Bei Nutzung des Leistungsangebots der feelix GmbH - egal ob online oder offline - gelten diese Allgemeinen Geschäftsbedingungen als verbindlich, sofern nicht etwas anderes schriftlich festgelegt wurde oder gesetzlich zwingend vorgeschrieben ist.",},
    { type: 'subTitle', text: '2. feelix Leistungen'},
    { type: 'textTitl', text: "2.1 Die feelix GmbH wird als Versicherungsmakler nach §34d der Gewerbeordnung für die Vermittlung von Versicherungen und als Anlage- und Investmentberater nach §34f der Gewerbeordnung für die Vermittlung von Anlagen und Investments in der oder den vom Verbraucher gewählten Sparte(n) tätig.",},
    { type: 'textTitl', text: "2.2 Die feelix GmbH erstellt als Versicherungsvermittler on- oder offline für Verbraucher ausschließlich unentgeltlich und unverbindlich Auskünfte und Vergleiche über Tarife und Konditionen von mit der feelix GmbH kooperierenden Versicherungs-, Bank-, Bauspar-, Depot- und sonstigen Finanzdienstleistungsunternehmen (nachfolgend “Anbieter” genannt). Dies geschieht auf Basis einer objektiven und ausgewogenen Marktbetrachtung durch dritte Analysehäuser. In den Vergleichen können nicht alle Versicherungsgesellschaften und nicht alle am Markt vorhandenen Versicherungstarife aufgeführt werden."},
    { type: 'textTitl', text: "2.3 Die Auswahl der mit der feelix GmbH kooperierenden Anbieter und deren Produkte obliegt ausschließlich der feelix GmbH. Die feelix GmbH nimmt unabhängig von Anbietern die Versicherungsinteressen des Verbrauchers wahr. Der Verbraucher kann jederzeit aktuelle Informationen darüber erhalten, mit welchen Anbietern die feelix GmbH zusammenarbeitet."},
    { type: 'textTitl', text: "2.4 Die feelix GmbH berücksichtigt im Rahmen ihrer Tätigkeit nur Anbieter, die von der Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin) beaufsichtigt werden und Vertragsbedingungen in deutscher Sprache anbieten. Ausländische Anbieter bleiben unberücksichtigt. Sofern die Art der Risiken oder die Marktverhältnisse es erfordern, ist es der feelix GmbH freigestellt, Verbraucher auch an im europäischen Dienstleistungsverkehr tätige Anbieter zu vermitteln. Eine rechtliche Verpflichtung hierzu besteht allerdings nicht. Produkte werden nicht an Direktversicherer oder Unternehmen vermittelt, die der feelix GmbH keine Vergütung gewähren."},
    { type: 'textTitl', text: "2.5 Um eine Tarifauskunft oder eine Vergleichsberechnung zu erhalten, muss der Verbraucher selbst und eigenverantwortlich die gewünschten Auswahl- bzw. die Suchkriterien festlegen. Etwaige vorbelegte Auswahl- bzw. Suchkriterien sind ausdrücklich nicht als Handlungsempfehlung zu verstehen."},
    { type: 'textTitl', text: "2.6 Entscheidet sich der Verbraucher auf Grundlage einer Vorschlags- oder Vergleichsberechnung für einen bestimmten Anbieter, so kann er entweder offline oder online eine konkrete Anfrage gerichtet auf den Abschluss eines Versicherungs- oder Finanzdienstleistungsvertrages abgeben."},
    { type: 'textTitl', text: "2.7 Die feelix GmbH vermittelt auf der Grundlage des vom Verbraucher online oder offline abgegebenen Angebots Versicherungs- und Finanzdienstleistungsverträge an den jeweiligen Anbieter."},
    { type: 'textTitl', text: "2.8 Die von der feelix GmbH vermittelten Versicherungs- und Finanzdienstleistungsverträge kommen ausschließlich zwischen dem Verbraucher und dem von ihm selbst ausgewählten Anbieter zustande. Das Zustandekommen des jeweiligen Versicherungs- oder Finanzdienstleistungsvertrages mit dem vom Verbraucher gewählten Anbieter kann von der feelix GmbH nicht garantiert und nicht beeinflusst werden. Ob ein Vertrag mit dem Anbieter zustande kommt, ist abhängig von den entsprechenden Bedingungen des jeweiligen Anbieters."},
    { type: 'textTitl', text: "2.9 Die konkreten Angebote der Anbieter können sich im Einzelfall hinsichtlich individueller Umstände von den einzelnen Tarifen oder Voraussetzungen, welche die feelix GmbH berechnet und angeboten werden, unterscheiden. Für Inhalt und Richtigkeit der Angebote sind ausschließlich die Anbieter verantwortlich."},
    { type: 'subTitle', text: '3. Gewährleistung'},
    { type: 'textTitl', text: "3.1 Die feelix GmbH wendet größte Sorgfalt bei der Erstellung und Pflege der Dateien und Inhalte auf. Gleichwohl kann die feelix GmbH keine Gewähr dafür übernehmen, dass die Dateien und Inhalte für die konkreten Bedürfnisse des Verbrauchers passend und richtig sind. Der Verbraucher wählt die Dateien und Inhalte eigenverantwortlich aus und verwendet diese eigenverantwortlich. Die Haftung der feelix GmbH für die falsche Verwendung der Dateien und Inhalte ist ausgeschlossen."},
    { type: 'textTitl', text: "3.2 Die feelix GmbH ist ferner nicht dazu verpflichtet, sämtliche am Markt befindlichen Versicherungs- und Finanzdienstleistungsunternehmen in die Vergleichsberechnungen einzubeziehen."},
    { type: 'textTitl', text: "3.3 Die Dateien und Inhalte dienen lediglich als Anhaltspunkt und Orientierung und ersetzen keinesfalls eine fachkundige Beratung. Der Verbraucher wird deshalb erforderlichenfalls anwaltlichen Rat einholen, bevor er die Dateien und Inhalte verwendet. Unterlässt der Verbraucher dies und entsteht aufgrund dessen ein Schaden, ist die Haftung der feelix GmbH hierfür ausgeschlossen."},
    { type: 'textTitl', text: "3.4 Eine Haftung der feelix GmbH für die Aktualität, Vollständigkeit und Richtigkeit der durchgeführten Vorschlags- und Vergleichsberechnungen sowie für die zeitnahe, vollständige und richtige Weiterleitung von Verbraucherdaten ist ausgeschlossen."},
    { type: 'textTitl', text: "3.5 Das Leistungsangebot der feelix GmbH über Versicherungs- und Finanzdienstleistungen stellt zu keiner Zeit und in keiner Form eine Anlageberatung oder eine Rechtsberatung für den Verbraucher dar. Die Leistungen der feelix GmbH sind als Hilfe für eine selbständige Entscheidung des Verbrauchers konzipiert, auf die die feelix GmbH keinen Einfluss hat. Keine der Informationen ist als Zusage einer Garantie oder Zusicherung zu verstehen. Bindend ist der Wortlaut des Versicherungsscheines. Der Verbraucher ist dazu verpflichtet, den genauen Inhalt des Versicherungsscheines zu prüfen und mögliche Abweichungen umgehend zu melden."},
    { type: 'textTitl', text: "3.6 Die Nutzung der von der feelix GmbH angebotenen Leistungen geschieht in jedem Fall auf eigenes Risiko. Die Haftung der feelix GmbH ist im Übrigen auf Vorsatz und grobe Fahrlässigkeit beschränkt. Davon ausgenommen ist die Haftung für Schäden an Körper, Leben und Gesundheit, für die die gesetzlichen Haftungsregeln uneingeschränkt gelten."},
    { type: 'textTitl', text: "3.7 Die feelix GmbH erfüllt ihre Verpflichtungen mit der Sorgfalt eines ordentlichen Kaufmanns. Die Haftung für die Verletzung beruflicher Sorgfaltspflichten ist auf die in der Verordnung über die Versicherungsvermittlung und -beratung (VersVermV) angegebenen Mindestversicherungssummen (Stand 2012 = 1,13 Mio. € je Versicherungsfall und 2,26 Mio. € für alle Versicherungsfälle eines Jahres) beschränkt, es sei denn, die feelix GmbH hat eine Kardinalspflicht verletzt oder eine Nebenpflicht vorsätzlich oder grob fahrlässig verletzt."},
    { type: 'textTitl', text: "3.8 Die feelix GmbH haftet nicht für die Beratungsfehler, Vergütung und Haftungsbegrenzung des Vorvermittlers, die durch bestehende gesetzliche Obliegenheiten des Verbrauchers nach Eintritt des Schadensfalls eintreten werden, § 86 Absatz 2 VVG."},
    { type: 'subTitle', text: '4. Pflichten Verbraucher'},
    { type: 'textTitl', text: "4.1 Die Eingabe und Übermittlung der persönlichen Daten durch den Verbraucher erfolgt online über die von der feelix GmbH vorbereiteten Eingabemasken oder über andere ausdrücklich von der feelix GmbH im Einzelfall vorgesehene Übermittlungswege. Für die Vollständigkeit und Richtigkeit sämtlicher vom Verbraucher in die Eingabemaske eingegebenen oder sonst übermittelten Daten ist ausschließlich der Verbraucher selbst verantwortlich."},
    { type: 'textTitl', text: "4.2 Sofern der Verbraucher falsche, unwahre oder unzureichende Angaben macht, ist die feelix GmbH berechtigt, die Suchanfrage unbearbeitet zu lassen. Für den Fall der Übermittlung von vorsätzlich falschen oder unwahren persönlichen Daten durch den Verbraucher behält sich die feelix GmbH vor, ihn von der Inanspruchnahme der angebotenen bzw. zur Verfügung gestellten Leistungen auszuschließen und Ersatz für dadurch eingetretene Schäden zu verlangen."},
    { type: 'textTitl', text: "4.3 Falsche und/oder unzureichende Angaben durch den Verbraucher/Versicherungsnehmer können zu einem Verlust des Versicherungsschutzes führen. Die feelix GmbH haftet nicht für falsche und/oder unzureichende Angaben in Vorschlags- und Vergleichsberechnungen sowie in Tarif- und Konditionenauskünften - egal ob offline oder online - durch den Verbraucher."},
    { type: 'textTitl', text: "4.4 Für jeden Fall der schuldhaften Zuwiderhandlung gegen das Verbot der gewerblichen bzw. selbständig beruflichen Nutzung wird eine Vertragsstrafe in Höhe von 5.001,00 Euro vereinbart. Die feelix GmbH ist berechtigt, erst nach mehrmaliger Verwirkung der Vertragsstrafe diese im Ganzen zu verlangen. Die Zusammenfassung mehrerer Einzelakte zu einer rechtlichen Einheit ist ausgeschlossen (Ausschluss des Fortsetzungszusammenhangs). Unbeschadet der Vertragsstrafe kann die feelix GmbH darüber hinaus Schadensersatz geltend machen und den Verbraucher von der weiteren Inanspruchnahme der angebotenen bzw. zur Verfügung gestellten Leistungen ausschließen."},
    { type: 'textTitl', text: "4.5 Sofern der Verbraucher das achtzehnte Lebensjahr noch nicht erreicht hat oder nicht voll geschäftsfähig ist, hat die feelix GmbH das Recht, die Suchanfrage unbearbeitet zu lassen."},
    { type: 'textTitl', text: "4.6 Ebenfalls untersagt ist das Anfertigen von vollständigen oder teilweisen Kopien der Anwendung der feelix GmbH und der darin enthaltenen Daten. Gleiches gilt für den Verkauf oder jede andere Art der Verbreitung oder Veröffentlichung der von der feelix GmbH offline oder online erstellten Vorschlags- bzw. Vergleichsberechnungen. Alle Urheber-, Nutzungs- und sonstigen Schutzrechte verbleiben bei der feelix GmbH."},
    { type: 'textTitl', text: "4.7 Mit der Nutzung des Leistungsangebotes der feelix GmbH erklärt sich der Verbraucher ausdrücklich mit diesen AGB einverstanden."},
    { type: 'subTitle', text: '5. Urheberrecht'},
    { type: 'textTitl', text: "5.1 Es verbleiben sämtliche Urheber-, Nutzungs- oder Schutzrechte bei feelix GmbH. Alle Urheber-, Nutzungs- und sonstigen Schutzrechte an den Dateien und Inhalten verbleiben bei der feelix GmbH. Der Verbraucher darf die Dateien und Inhalte nur zu eigenen Zwecken nutzen und ist nicht berechtigt, sie im Internet oder auf sonstige Weise Dritten zur Verfügung zu stellen, sei es entgeltlich oder unentgeltlich, soweit nicht der Zweck der Dateien und Inhalte dies gebietet. Die gewerbliche Vervielfältigung und der Weiterverkauf der Dateien und Inhalte sind ausgeschlossen."},
    { type: 'textTitl', text: "5.2 Branding, Copyright- und Urheberhinweise der feelix GmbH dürfen aus den Dateien und Inhalten nicht entfernt werden."},
    { type: 'subTitle', text: '6. Datenschutz'},
    { type: 'textTitl', text: "6.1 Der Verbraucher stimmt ausdrücklich der Nutzung, Speicherung und Verarbeitung personenbezogener Daten zu. Diese Daten können auf Wunsch des Verbrauchers gesperrt werden."},
    { type: 'textTitl', text: "6.2 Der Verbraucher stimmt ausdrücklich der Weitergabe personenbezogener Daten an Dritte zum Zweck der Bearbeitung seiner Anfrage oder seines Antrags zu."},
    { type: 'textTitl', text: "6.3 Der Verbraucher hat jederzeit das Recht, von der feelix GmbH über den ihn betreffenden gespeicherten Datenbestand vollständig und unentgeltlich Auskunft zu erhalten."},
    { type: 'subTitle', text: '7. Gerichtsstand'},
    { type: 'textTitl', text: "7.1 Für alle Streitigkeiten zwischen dem Verbraucher und der feelix GmbH, gleich aus welchem Rechtsgrund, wird als Gerichtsstand Berlin vereinbart, sofern es sich bei dem Verbraucher um einen Kaufmann im Sinne des Handelsgesetzbuches handelt, der Verbraucher keinen allgemeinen Gerichtsstand in der Bundesrepublik Deutschland hat oder nach Vertragsschluss seinen Wohnsitz bzw. gewöhnlichen Aufenthaltsort aus dem Geltungsbereich der Zivilprozessordnung verlegt oder dieser zum Zeitpunkt der Klageerhebung nicht bekannt ist."},
    { type: 'textTitl', text: "7.2 Für sämtliche Streitigkeiten zwischen dem Verbraucher und der feelix GmbH gilt ausschließlich deutsches Recht."},
    { type: 'subTitle', text: '8. Schlussbestimmungen'},
    { type: 'textTitl', text: "Sollten einzelne Bestimmungen dieser AGB unwirksam sein bzw. werden, so bleiben die Bedingungen im Übrigen wirksam."},
    { type: 'catTitle', text: 'Nutzungsrechte'},
    { type: 'subTitle', text: '1. Nutzung'},
    { type: 'textTitl', text: "1.1 Die Nutzung der Leistungen der feelix GmbH ist nur volljährigen Privatpersonen (im folgenden “Verbraucher” genannt) zu Zwecken gestattet, die nicht einer gewerblichen oder selbständigen beruflichen Tätigkeit zugerechnet werden können. Eine Nutzung der Leistungen der feelix GmbH zu gewerblichen Zwecken oder im Rahmen einer selbständigen beruflichen Tätigkeit ist ausdrücklich untersagt. Verbraucher sind Personen, die ihre privaten Versicherungs-, Geldanlage-, Kredit- und Kontodaten verwalten und bei Bedarf neue Verträge selbst abschließen."},
    { type: 'textTitl', text: "1.2 Vergleiche dürfen nicht von Vermittlern für die Kundenberatung genutzt werden. Eine gewerbliche Nutzung ist ausdrücklich nicht gestattet."},
    { type: 'textTitl', text: "1.3 Die ermittelten Daten und Informationen dürfen nicht ohne Genehmigung der feelix GmbH an Medienvertreter, Presse, Fernsehen usw. weitergegeben werden. Diese Regelung betrifft auch alle Online- und Internet-Dienste, wie z.B. Vergleichsportale. Die Weitergabe von Informationen, auch Teilen daraus, ist ausdrücklich untersagt."},
    { type: 'textTitl', text: "1.4 Der Zugriff auf Programmdaten jeglicher Art, gleich mit welchen technischen Möglichkeiten, ist ausdrücklich untersagt. Für jeden Fall der Zuwiderhandlung und unter Ausschluss der Einrede des Fortsetzungszusammenhangs wird eine Vertragsstrafe in Höhe von 10.000 Euro vereinbart. Die Geltendmachung weiterer Schadenersatzansprüche bleibt ausdrücklich vorbehalten."},
    { type: 'subTitle', text: '2. Urheberrecht'},
    { type: 'textTitl', text: "2.1 Die von der feelix GmbH veröffentlichten Inhalte sind urheberrechtlich geschützt (Copyright). Alle Rechte für den Inhalt und die Gestaltung befinden sich ausschließlich im Besitz der feelix GmbH."},
    { type: 'textTitl', text: "2.2 Das vollständige oder teilweise Reproduzieren, Verbreiten, Übermitteln (elektronisch oder auf andere Weise), Modifizieren oder Benutzen von Inhalten für öffentliche oder kommerzielle Zwecke ist ohne die vorherige schriftliche Zustimmung der feelix GmbH untersagt."},
    { type: 'subTitle', text: '3. Markenrecht'},
    { type: 'textTitl', text: "3.1 Die von der feelix GmbH verwendeten Marken und Logos sind rechtlich geschützt und dürfen weder inhaltlich noch optisch verändert werden."},
    { type: 'textTitl', text: "3.2 Es ist nicht gestattet, diese Marken ohne vorherige schriftliche Zustimmung der feelix GmbH zu nutzen."},
    { type: 'subTitle', text: '4. Haftungsausschluss'},
    { type: 'textTitl', text: "4.1 Alle Inhalte, die von der feelix GmbH bereitgestellt werden, wurden mit größtmöglicher Sorgfalt erstellt. Die feelix GmbH übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte."},
    { type: 'textTitl', text: "4.2 Die feelix GmbH behält sich das Recht vor, an den bereitgestellten Informationen und Inhalten jederzeit Änderungen oder Ergänzungen vorzunehmen."},
    { type: 'textTitl', text: "4.3 Die Nutzung der Informationen und Inhalte erfolgt auf eigene Gefahr des Verbrauchers."},
    { type: 'textTitl', text: "4.4 Mit der reinen Nutzung der Informationen der feelix GmbH kommt keinerlei Vertragsverhältnis zwischen dem Verbraucher und der feelix GmbH zustande."},
    { type: 'textTitl', text: "4.5 Die feelix GmbH haftet für Vorsatz und grobe Fahrlässigkeit sowie unabhängig vom Verschuldungsgrad für Schäden aus der Verletzung einer Pflicht aus § 60 oder § 61 VVG sowie für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die auf einer Pflichtverletzung eines gesetzlichen Vertreters oder Erfüllungsgehilfen von der feelix GmbH beruhen."},
    { type: 'textTitl', text: "4.6 Die Haftung für die leicht fahrlässige Verletzung von vertragswesentlichen Pflichten – das sind Verpflichtungen, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglichen und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und vertrauen darf – ist auf den typischen und vorhersehbaren Schaden begrenzt. Im Übrigen ist die Haftung der feelix GmbH für leicht fahrlässige Pflichtverletzungen ausgeschlossen."},
    { type: 'subTitle', text: '5. Links'},
    { type: 'textTitl', text: "5.1 Die feelix GmbH weist darauf hin, dass die Inhalte der Webseiten, die über einen Hyperlink aufgerufen werden können, von Dritten stammen und nicht die Meinung der feelix GmbH darstellen. Diese Webseiten unterliegen der Haftung der jeweiligen Betreiber."},
    { type: 'textTitl', text: "5.2 Die feelix GmbH hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Die feelix GmbH hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten."},
    { type: 'textTitl', text: "5.3 Das Setzen von externen Links bedeutet nicht, dass sich die feelix GmbH die hinter dem Verweis oder Link liegenden Inhalte zu eigen macht."},
    { type: 'textTitl', text: "5.4 Eine ständige Kontrolle dieser externen Links ist für die feelix GmbH ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht."},
    { type: 'textTitl', text: "5.5 Die feelix GmbH lehnt jegliche Haftung für materielle oder ideelle Schäden, insbesondere auch für Folgeschäden, ab, die durch die Nutzung der von ihr zur Verfügung gestellten Informationen verursacht wurden, sofern sie nicht durch vorsätzliches oder grob fahrlässiges Verhalten verursacht wurden."},
    { type: 'subTitle', text: '6. Datenschutz'},
    { type: 'textTitl', text: "Informationen zur Erhebung und Nutzung von Verbraucherdaten beim Besuch der Webseiten der feelix GmbH findet der Verbraucher in der Datenschutzerklärung der feelix GmbH: http://www.myfeelix.de/datenschutzbestimmungen/."},
    { type: 'catTitle', text: 'Supportbedingungen'},
    { type: 'subTitle', text: '1. Vertragsimport'},
    { type: 'textTitl', text: "1.1 Du kannst die feelix GmbH beauftragen, Vertragsdaten und Dokumente vom jeweiligen Anbieter einzuholen. Hierzu ist die vorherige Unterzeichnung des Premiumauftrages notwendig."},
    { type: 'textTitl', text: "1.2 Mir ist bekannt, dass der Import meiner Vertragsdaten und Dokumente vom Produktgeber die Unterzeichnung des Premiumauftrages voraussetzt."},
    { type: 'textTitl', text: "1.3 Der Vertragsimport erfolgt dabei ohne den direkten Betreuerwechsel beim Produktanbieter. Dies bedeutet, dass mein aktueller Vermittler/Makler weiterhin Betreuer des jeweiligen Vertrages bleibt."},
    { type: 'textTitl', text: "1.4 Ich entscheide gesondert pro Vertrag in der feelix Finanz App, ob ich die Betreuung meines Vertrages von meinem alten Vermittler/Makler zu feelix übertragen möchte."},
    { type: 'textTitl', text: "1.5 Für den Vertragsimport übernimmt feelix eine Gewährleistung, sofern der Produktanbieter die Daten bereitstellt. Mir ist bekannt, dass der Import meiner Vertragsdaten und Dokumente in der Regel 7-14 Tage in Anspruch nimmt."},
    { type: 'subTitle', text: '2. Inkasso'},
    { type: 'textTitl', text: "Das Inkasso der geschuldeten Beiträge des Verbrauchers verbleibt bei den jeweiligen Anbietern."},
    { type: 'subTitle', text: '3. Abtretungsverbot'},
    { type: 'textTitl', text: "Sämtliche sich aus diesem Vertragsverhältnis ergebenden Rechte oder Ansprüche des Verbrauchers gegen die feelix GmbH sind nicht übertragbar, abtretbar oder belastbar."},
    { type: 'subTitle', text: '4. Eingeschränkter Support'},
    { type: 'textTitl', text: "Die unten aufgeführten Gesellschaften akzeptieren entweder keine Makler oder zahlen keine Bestands- oder Abschlussprovisionen. Die feelix GmbH kann in diesen Fällen nicht die Rolle des Maklers einnehmen und somit auch keine Leistungen bzw. Support für diese Verträge des Verbrauchers erbringen. Der Verbraucher kann aber die Verträge auf jeden Fall manuell in seinen Vertragsordner aufnehmen. Folgende Produktanbieter fallen darunter:"},
    { type: 'textTitl', text: "Aachen Münchener AG\nAdmiral direkt GmbH\nAsstel - Direktversicherer der Gothaer AG\nBavaria Direkt AG\nCosmos Direkt AG\nCosmos LV AG\nDA Direkt AG\nD.A.S. GmbH\nDocura VVaG\nDelta Direkt AG\nDebeka AG\nDEVK AG\nDLVAG AG\nEnvivas AG\nEuropa AG\nFeuersozität AG\nFriends Provident Life AG\nGaranta AG\nGrundeigentümer VVaG\nHamburger Vers. AG\nHUK 24 AG\nHUK Coburg AG\nMecklenburgische a.G.\nOVAG Ostdeutsche Vers. AG"},
    { type: 'textTitl', text: "Ebenso kann die feelix GmbH keine Leistungen bzw. Support für Verträge des Verbrauchers der folgenden Produktarten erbringen. Auch diese Produktarten kann der Verbraucher manuell in seinem Vertragsordner erfassen:"},
    { type: 'textTitl', text: "Gesetzliche Krankenkassen\nGesetzliche Rentenversicherung\nGesetzlichen Unfallversicherung\nGirokonten"},
    { type: 'subTitle', text: '5. Bonusprogramm'},
    { type: 'textTitl', text: "Mit dem Premiumauftrag nehme ich automatisch am Bonusprogramm von feelix teil und erhalte ab sofort für jeden neu über feelix abgeschlossenen Vertrag und durch bestehende Verträge, die sich in der Betreuung von feelix befinden, Bonuspunkte."},
    { type: 'textTitl', text: "Die detaillierte Beschreibung der Teilnahmebedingungen sowie die Punkteverteilung pro Vertrag finde ich jederzeit online auf der Homepage von feelix."},
    { type: 'textTitl', text: "Bereits ab 50 gesammelten Bonuspunkten kann ich die Punkte gegen einen Gutschein meiner Wahl, z.B. von Amazon, Zalando oder Media Markt, im Wert von 20 € einlösen."},
    { type: 'subTitle', text: 'AGB Änderungen'},
    { type: 'textTitl', text: "1.1 Die feelix GmbH behält sich vor, Bestimmungen dieser AGB jederzeit und ohne Nennung von Gründen unter Beachtung der im Folgenden dargestellten Voraussetzungen zu ändern:"},
    { type: 'textTitl', text: "1.2 Geänderte Bestimmungen werden dem Verbraucher per E-Mail vor ihrem Inkrafttreten zugesendet. Widerspricht der Verbraucher der Geltung der neuen AGB nicht innerhalb von sechs Wochen nach Empfang der E-Mail, gelten die geänderten AGB als angenommen. Die feelix GmbH verpflichtet sich, dem Verbraucher in der E-Mail, die die geänderten Bestimmungen enthält, gesondert auf die Bedeutung der Sechswochenfrist hinzuweisen (insbesondere darauf, dass die geänderten AGB als von dem Verbraucher angenommen gelten, wenn der Verbraucher nicht innerhalb der Frist nach Empfang der E-Mail den neuen AGB widerspricht)."},
    { type: 'textTitl', text: "1.3 Widerspricht der Verbraucher der Geltung der neuen AGB innerhalb der vorgenannten Frist, bleibt die feelix GmbH befugt, das Vertragsverhältnis mit dem Verbraucher ordentlich mit einer Frist von vierzehn Tagen zu kündigen."},
    //info privacy
    { type: 'secTitle', text: '02 Datenschutzrichtlinien'},
    { type: 'subTitle', text: 'Dein Datenschutz mit feelix'},
    { type: 'textTitl', text: "Deine Persönlichkeitsrechte sind uns wichtig, deshalb haben wir Datenschutzbestimmungen entwickelt, welche regeln, wie wir Daten erheben, verwenden, offenlegen, weitergeben und speichern. Wir verpflichten uns aus diesem Grund Deine Informationen vertrauensvoll und verantwortungsbewusst zu verarbeiten und halten uns bei der Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten streng an die gesetzlichen Bestimmungen des Bundesdatenschutzgesetzes (BDSG) und des Telemediengesetzes (TMG)."},
    { type: 'subTitle', text: 'Wer ist verantwortlich für die Datenerhebung in unserer App:'},
    { type: 'textTitl', text: "Verantwortlicher im Sinne der Datenschutz-Grundverordnung anderer in den Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und sonstiger datenschutzrechtlicher Bestimmungen ist die:"},
    { type: 'textTitl', text: "feelix GmbH (im Folgenden: „wir“, „uns“)\nVertreten durch den Geschäftsführer Tilo Hammer\nMethfesselstr. 50\n10965 Berlin\nTel: 030-577 005 730\nFax: 030-577 005 731\nEmail: info@myfeelix.de\n"},
    { type: 'subTitle', text: 'Wer ist unser Datenschutzbeauftragte?'},
    { type: 'textTitl', text: "Der Datenschutzbeauftragte des für die Verarbeitung Verantwortlichen ist:"},
    { type: 'textTitl', text: "Tilo Hammer\nMethfesselstr. 50\n10965 Berlin\nTel: 030-577 005 730\nFax: 030-577 005 731\nEmail: datenschutz@myfeelix.de\n"},
    { type: 'subTitle', text: 'Du hast Fragen?'},
    { type: 'textTitl', text: "Mach Dich in aller Ruhe mit unseren Maßnahmen zum Schutz Deiner Persönlichkeitsrechte vertraut und sende uns gerne eine E-Mail an datenschutz@myfeelix.de, falls Du Fragen zu unseren Datenschutzbestimmungen haben solltest. Im Zuge der Weiterentwicklung und zur Verbesserung unseres Services für Dich, können auch Änderungen dieser Datenschutzbestimmungen erforderlich werden. Daher empfehlen wir Dir, diese Datenschutzbestimmungen ab und zu erneut aufzurufen und zu lesen."},
    { type: 'subTitle', text: 'Sicherheitshinweis'},
    { type: 'textTitl', text: "Wir verpflichten uns, Deine personenbezogenen Daten durch Ergreifung aller technischen und organisatorischen Möglichkeiten so zu speichern, dass sie für Dritte nicht zugänglich sind. Bei der Kommunikation per E-Mail kann die vollständige Datensicherheit von uns nicht gewährleistet werden, so dass wir Dir bei vertraulichen Informationen den Postweg empfehlen. Alle Deine Daten werden ausschließlich in Deutschland in modernsten Rechenzentren gehostet."},
    { type: 'catTitle', text: '01 Zu welchem Zweck und auf welcher Rechtsgrundlage verarbeiten wir Deine Daten?'},
    { type: 'textTitl', text: "Wir verarbeiten Deine Daten entsprechend den Vorgaben der Europäischen Datenschutz Grundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG). Die Verarbeitung Deiner personenbezogener Daten erfolgt unter anderem aufgrund der von Dir erteilten Einwilligung. Umfang und Zweck der diesbezüglichen Datenverarbeitung ergibt sich aus der Einwilligungserklärung. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO). Weiterhin erfolgt die Erhebung und Verarbeitung personenbezogener Daten zur Begründung eigener vertraglicher Verhältnisse zwischen Dir und uns. Wir benötigen die entsprechenden Daten ebenfalls zur Durchführung vorvertraglicher Maßnahmen im Rahmen der Vermittlung von Verträgen zwischen Dir und dem jeweiligen Produktgeber. Die Erhebung und Verarbeitung Deiner personenbezogenen Daten erfolgt ebenfalls, um Dich bei der Schadensabwicklung betreuen zu können sowie zur Änderung und/oder Beendigung bestehender Verträge. Als Rechtsgrundlage hierfür dient Art. 6 Abs. 1 lit. b DSGVO."},
    { type: 'textTitl', text: "Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen, insbesondere gesetzlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung (z.B. Verteidigung bei rechtlichen Streitigkeiten, Anspruchsdurchsetzung etc.)."},
    { type: 'textTitl', text: "Zur Erbringung der vertraglich übernommenen Vermittlungs- und Betreuungstätigkeit benötigt die feelix GmbH Deine, sowie die personenbezogenen Daten der über Dich eventuell mit zu betreuenden Personen. Ferner benötigen wir Deine Daten besonderer Art, wie zum Beispiel Gesundheitsdaten, monatliche Ausgaben oder Steuer- und Sozialversicherungsdaten. Die feelix GmbH erhebt und speichert diese Daten, um sie im Rahmen von Anfragen oder Beantragungen, von Vertragsabschlüssen sowie zur Vertragsbetreuung und -abwicklung an Dritte (Gesellschaften wie z.B. Versicherungen, Banken oder Bausparkassen und deren Tochtergesellschaften inkl. Maklerbetreuer) zu übermitteln."},
    { type: 'subTitle', text: 'Wer erhält Zugriff auf Deine Daten?'},
    { type: 'textTitl', text: "Innerhalb unseres Unternehmens erhalten diejenigen Mitarbeiter Zugriff auf Deine Daten, die diese zur Erfüllung unserer vertraglichen und/oder gesetzlichen Pflichten benötigen, also mit der vertraglichen Abwicklung betraut sind. In diesem Zusammenhang können das auch von uns eingesetzte Dienstleister und Erfüllungsgehilfen sein.Soweit wir Ihre Daten an Empfänger außerhalb unseres Unternehmens weitergeben, erfolgt dies ausschließlich zur Erfüllung unserer Vertragspflichten im Rahmen unseres Geschäftszweckes, aufgrund gesetzlicher Bestimmungen oder Ihrer ausdrücklich erteilten Einwilligung. Zur Erfüllung unserer Vertragspflichten arbeiten wir u.a. mit folgenden Stellen zusammen:"},
    { type: 'textTitl', text: "• Kooperationspartner\n• Lieferanten\n• Technische und sonstige Dienstleister, die für uns tätig sind\n• Rechtsanwälte, Steuerberater, Wirtschaftsprüfer"},
    { type: 'textTitl', text: "Die feelix GmbH vermittelt keine direkten Finanzprodukte und fungiert als anbieterunabhängige Beratungs- und Supportstelle. Aus diesem Grund arbeitet die feelix GmbH mit verschiedenen Kooperationspartnern zusammen und Du erklärst Dich mit der Annahme unserer Datenschutzbestimmungen damit einverstanden, dass wir Deine personenbezogenen Daten auch an folgende Unternehmen weitergeben können:"},
    { type: 'textTitl', text: "blau direkt GmbH & Co.KG, Kaninchenborn 31, 23560 Lübeck\n\nHetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen"},
    { type: 'subTitle', text: 'Warum arbeiten wir mit diesen Partnern zusammen?'},
    { type: 'textTitl', text: "blau direkt unterstützt uns bei der Anbindung an Versicherungsgesellschaften. Hetzner und aconitas sind unsere deutschen Hosting Partner bei denen wir unsere Plattform, Webseiten, sowie alle Kunden und Vertragsdaten hosten."},
    { type: 'subTitle', text: 'Grundsätzliches zur Verarbeitung personenbezogener Daten'},
    { type: 'textTitl', text: "Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind. Wir verarbeiten personenbezogene Daten grundsätzlich nur, wenn Du hierzu Deine Einwilligung erteilt hast oder die Datenverarbeitung durch gesetzliche Vorschriften erlaubt ist. Rechtsgrundlage ist Art. 6 Abs. 1 EU-Datenschutzgrundverordnung (DSGVO). Nach dieser Vorschrift ist eine Verarbeitung personenbezogener Daten nur zulässig, wenn die betroffene Person einwilligt (Art. 6 Abs.1 lit. a DSGVO) oder die Verarbeitung zu einer der folgenden Zwecke erforderlich ist:"},
    { type: 'textTitl', text: "Zur Erfüllung eines Vertrages mit der betroffenen Person oder zur Durchführung vorvertraglicher Maßnahmen auf Anfrage der betroffenen Person (Art. 6 Abs.1 lit. b DSGVO). "},
    { type: 'textTitl', text: "Zur Erfüllung einer rechtlichen Verbindlichkeit unseres Unternehmens (Art. 6 Abs.1 lit. c DSGVO)."},
    { type: 'textTitl', text: "Zum Schutz lebenswichtiger Interessen der betroffenen Person oder einer anderen natürlichen Person (Art. 6 Abs.1 lit. d DSGVO)."},
    { type: 'textTitl', text: "Zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder die unserem Unternehmen von der öffentlichen Verwaltung übertragen wurde (Art. 6 Abs.1 lit. e DSGVO)."},
    { type: 'textTitl', text: "Zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten, sofern nicht die Interessen, Grundrechte und Grundfreiheiten der betroffenen Person zum Schutz personenbezogener Daten überwiegen (Art. 6 Abs.1 lit. f DSGVO)."},
    { type: 'textTitl', text: "Du willigst ferner mit der Annahme ein, dass die feelix GmbH im Rahmen von Produktvergleichen, Vertragsabschlüssen und Antragsbearbeitungen von Finanzverträgen Daten einschließlich Daten besonderer Art, an die genannten Kooperationspartner, technische Dienstleister oder sonstige Dienstleister übermittelt und von diesen empfängt. Dies ist erforderlich, um die vorstehend genannten Zwecke zu erfüllen, insbesondere geht es um den weiteren Datentransfer an Dritte Gesellschaften, wie z.B. Versicherer, Banken oder Bausparkassen und deren Tochtergesellschaften inkl. Maklerbetreuer."},
    { type: 'textTitl', text: "Du willigst ausserdem ein, dass die von der feelix GmbH angesprochenen Versicherer im erforderlichen Umfang Daten, die sich aus den Antragsunterlagen (z.B. Deckungsauftrag) oder der Vertragsdurchführung (z. B. Beiträge, Versicherungsfälle, Risiko/Vertragsänderungen) ergeben, an Rückversicherer zur Beurteilung des Risikos und zur Abwicklung der Rückversicherung sowie zur Beurteilung des Risikos und der Ansprüche an andere Versicherer und ihre Verbände, übermitteln dürfen. Diese Einwilligung gilt unabhängig vom Zustandekommen des Vertrages auch für entsprechende Prüfungen bei anderweitig beantragten Versicherungsverträgen und künftigen Anträgen. Diese Datenübermittlung führt zu keiner Änderung der Zweckbestimmung."},
    { type: 'textTitl', text: "Neben den Kundenstammdaten werden die von Dir getätigten Vergleiche und Abschlüsse gespeichert und sind Dir über Deinen personifizierten Account auf einen Blick einsehbar. Die Daten werden von der feelix GmbH zentral gespeichert und nur an verbundene Unternehmen der feelix GmbH, die die jeweiligen Vergleichsrechner (Versicherungen, Kredite, Geldanlagen) betreiben, für die Durchführung des jeweiligen Vergleichs, weitergegeben. Die feelix App ist Passwort geschützt. Auf Basis des 128 Bit-SSL Verschlüsselungs-Verfahrens verschlüsseln wir Deine Daten und übertragen diese im abgesicherten https-Modus."},
    { type: 'subTitle', text: 'Speicherdauer und Datenlöschung'},
    { type: 'textTitl', text: "Deine personenbezogenen Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den Gesetzgeber vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht. Vorgeschriebene Speicherfristen in diesem Sinne sind z.B. steuerrechtliche oder handelsrechtliche Aufbewahrungsfristen."},
    { type: 'subTitle', text: 'Anmeldung'},
    { type: 'textTitl', text: "Um Dich in unsere App anmelden zu können benötigen wir von Dir Deinen Vor- und Nachnamen, Deine Anrede, Deine Emailadresse und Deine Mobilfunknummer. Desweiteren musst Du ein möglichst sicheres Passwort auswählen mit dem Du Deinen Account schützt. Ein sicheres Passwort hat mindestens 10 Zeichen und besteht aus Klein- und Großbuchstaben sowie Zahlen und Sonderzeichen. Alle diese Angaben sind Pflichtangaben und sind auch als solche gekennzeichnet. Warum benötigen wir Deine Emailadresse und Deine Mobilfunknummer?"},
    { type: 'textTitl', text: "Deine Emailadresse benötigen wir, damit wir Dir nach der Anmeldung Deinen Account mit dem doppelten Authentifizierungsverfahren absichern können. Ausserdem ist Deine Emailadresse auch gleichzeitig Dein Benutzernamen, den du zusammen mit Deinem Passwort benötigst, um Dich in Deinem Account einzuloggen."},
    { type: 'textTitl', text: "Deine Mobilfunknummer benötigen wir zur doppelten Authentifizierung, denn wir senden Dir einen Code per SMS, den Du in unserer App eingeben musst. Erst dann ist Deine Anmeldung vollständig."},
    { type: 'subTitle', text: 'Maklervertrag und Erstauskunft'},
    { type: 'textTitl', text: "Nachdem Du Dich in der App registriert hast, hast Du die Wahl die App völlig kostenfrei zu nutzen in dem Du alle Daten selbst eingibst. Solltest Du dies nicht wollen und lieber die genannten Maklerservice (z.B. Vertragsimport) in Anspruch nehmen, dann benötigen wir von Dir eine Maklervertrag mit dem wird dann als Dein Versicherungsmakler, als Dein Finanzanlagenvermittler und als Dein Immobilien Darlehensvermittler tätig werden können. Diese Vollmacht ermöglicht es uns Willenserklärungen für dich gegenüber den Versicherern und Banken abzugeben und von diesen entgegenzunehmen, damit wir mit Deiner ausdrücklichen schriftlichen Zustimmung Verträge ändern, abschließen oder kündigen oder Informationen von den Versicherern und Banken für dich abrufen können. Wir werden niemals ohne Deine schriftliche Zustimmung Deine Verträge ändern oder kündigen bzw. Neue für Dich abschließen. Ein Muster dieses Maklervertrages kannst Du Dir hier einsehen. Dieser Vertrag wird durch eine digitale Unterschrift auf einem touchfähigen Endgerät von Dir unterzeichnet. Zusätzlich kannst Du den Maklervertrag auch als PDF speichern, ausdrucken oder wir senden Dir ein Exemplar per Post zu. Du kannst den Maklervertrag selbstverständlich auch jederzeit wieder kündigen.\nIn unserer Erstinformation informieren wir Dich über unsere verschiedenen Makler und Vermittlertätigkeiten. Hier kannst du die Erstinformation einsehen."},
    { type: 'textTitl', text: "Zur Erbringung der vertraglich übernommenen Vermittlungs- und Betreuungstätigkeit benötigen wir Deine, sowie die personenbezogenen Daten der über Dich eventuell mit zu betreuenden Personen. Ferner benötigen wir Deine Daten besonderer Art, wie zum Beispiel Gesundheitsdaten, monatliche Ausgaben oder Steuer- und Sozialversicherungsdaten. Wir erheben und speichern diese Daten, um sie im Rahmen von Anfragen oder Beantragungen, von Vertragsabschlüssen sowie zur Vertragsbetreuung und -abwicklung an Dritte (Gesellschaften wie z.B. Versicherungen, Banken oder Bausparkassen und deren Tochtergesellschaften inkl. Maklerbetreuer) zu übermitteln."},
    { type: 'catTitle', text: '02 Cookies und Externe Dienstleister'},
    { type: 'textTitl', text: "Unsere App verwendet teilweise so genannte Cookies. Cookies richten auf Deinem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Deinem Rechner abgelegt werden und die Dein Browser speichert.\nDie meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Deines Besuchs automatisch gelöscht. Andere Cookies bleiben auf Deinem Endgerät gespeichert, bis Du diese löscht. Diese Cookies ermöglichen es uns, Deinen Browser beim nächsten Besuch wiederzuerkennen.\nSie kannst Du Deinen Browser so einstellen, dass Du über das Setzen von Cookies informiert wirst und Cookies nur im Einzelfall erlaubst, die Annahme von Cookies für bestimmte Fälle oder generell ausschließt sowie das automatische Löschen der Cookies beim Schließen des Browser aktivierst. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein. Rechtsgrundlage hierfür ist Art. 6 Abs.1 lit. f DSGVO."},
    { type: 'subTitle', text: 'Datenerfassung bei der Installation der App'},
    { type: 'textTitl', text: "Nachdem Du unsere App in einem der bekannten App Stores (z.B Google Play oder Apple IOS) heruntergeladen hast, werden bestimmte Daten an den Store übertragen, so z.B Dein Benutzernamen, Deine Emailadresse, Deine Kundennummer, der Zeitpunkt des Downloads und die Gerätekennziffer. Diese Datenerhebung liegt außerhalb unseres Einflussbereichs und wir verarbeiten diese Daten nur, sofern es für das Installieren auf Deinem Endgerät notwendig ist."},
    { type: 'textTitl', text: "Während Du die App nutzt übermittelt diese bestimmte technische Daten an uns. Dabei handelt es sich vor allem um Nutzungsdaten wie z.B. zur Dauer der Nutzung und welche Funktionen Du genutzt hast. Im einzelnen sind das folgende Daten:"},
    { type: 'textTitl', text: "●  verwendetes Betriebssystem\n●   IP Adresse Deines Gerätes\n●    Art der Internetverbindung\n●   Menge der gesendeten Daten\n●   Datum und Uhrzeit der Nutzung\n●    verwendeter Browser\n●  verwendete Sprache\n●   Region\n●   Funktionsaufrufe in der App\n●  App Version"},
    { type: 'textTitl', text: "Diese Daten werden von uns ausschließlich getrennt von Deinem Benutzer Account gespeichert."},
    { type: 'textTitl', text: "Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht von uns vorgenommen. Wir behalten uns jedoch vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden."},
    { type: 'textTitl', text: "Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 lit. f DSGVO. Die Speicherung ist erforderlich, um die Funktionsfähigkeit unserer App und die korrekte Darstellung der Inhalte zu gewährleisten. Weiterhin dienen die Daten unserer Statistik und der ständigen Optimierung unserer Inhalte. Schließlich erfolgt eine Speicherung, um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen zur Verfügung zu stellen."},
    { type: 'textTitl', text: "Es erfolgt keine Weitergabe der Daten an Dritte, sofern keine gesetzliche Offenbarungspflicht besteht. Da die Erfassung und Speicherung der Daten in den Logfiles für einen störungsfreien Betrieb der App zwingend erforderlich ist, besteht für den Nutzer keine Widerspruchsmöglichkeit.\nDie Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Soweit die Erhebung zur funktionsfreien Bereitstellung der App erfolgte, ist dies mit Beendigung der Appsitzung der Fall."},
    { type: 'subTitle', text: 'App Berechtigungen'},
    { type: 'textTitl', text: "Damit Du die vollen Funktionalität der App nutzen kannst musst Du uns folgende App Berechtigungen gewähren:"},
    { type: 'subTitle', text: 'Android (Google Play)'},
    { type: 'textTitl', text: "Im Play Store werden einzelne Berechtigungen in so genannte Berechtigungsgruppen zusammengefasst. Als Beispiel: Die Berechtigungsgruppe SMS besteht aus der App-Berechtigung „SMS lesen“ und „SMS schreiben“. D.h. es kann vorkommen, dass Berechtigungen gebündelt werden und wir deshalb eine  Berechtigung für eine Funktion von Dir einholen müssen, obwohl wir nur eine Teilfunktion für die App benötigen. Beim Download von Anwendungen werden seit Mitte des Jahres 2014 nur noch die eingeforderten App-Berechtigungsgruppen angezeigt. Hast Du automatische Updates aktiviert und verlangt eine App bei der Aktualisierung nach weiteren Rechten innerhalb einer von Dir bereits freigegebenen Gruppe, erhältst Du keine Benachrichtigung darüber. Im einzelnen haben wir die Berechtigungen aufgeführt, die die App benötigt und erklären Dir wie wir sie nutzen:"},
    { type: 'textTitl', text: "Identität - Konten auf dem Gerät finden\nMit dieser Berechtigung können wir feststellen, ob Du ein Google Konto hast. Dies ist Voraussetzung um Dir z.B. Pushmitteilungen schicken zu können. Es wird aber weder die Information welche Konten Du nutzt an uns übermittelt noch irgendwelche Daten aus den Konten."},
    { type: 'textTitl', text: "Fotos/Medien/Dateien - Ändern oder löschen der Inhalte Deiner SD Karte und Lesen der Inhalte auf Deiner SD Karte\nWenn Du Dokumente fotografierst und in deinen Account hochladen willst, dann kannst du das über die App direkt ansteuern. Diese Fotos können auch aus Platzgründen auf Deiner SD Karte gespeichert sein, daher benötigt die App auch hier Zugriff."},
    { type: 'textTitl', text: "Kamera - Bilder und Videos aufnehmen\nDie App steuert Deine Kamera direkt an, so ist es einfacher z.B. eine Schadensmeldung direkt aus der App mit Fotos zu versehen."},
    { type: 'textTitl', text: "WLAN-Verbindungsinformationen - Wifi Verbindungen ansehen\nIn dem die App erkennen kann ob Du dich im Wifi befindest wird verhindert, dass du z.B. über mobile Datenverbindungen größere Datenmengen verbrauchst, um Deinen Datentarif nicht zu belasten."},
    { type: 'textTitl', text: "Geräte-ID & Anrufinformationen - Telefonstatus und Identität lesen\nWir lesen Deinen Telefonstatus und Deine Identität aus um sicherzustellen, dass nicht jemand in Deinem Namen einen Account eröffnet und dann Zugriff auf Daten erhält, die ihn nichts angehen."},
    { type: 'textTitl', text: "Sonstiges - Kompletter Netzwerkzugriff, Standby-Modus deaktivieren, Vibration steuern, Netzwerkverbindungen ansehen\nDas sind Berechtigungen, die Android standardmäßig vergibt, die wir aber nicht nutzen."},
    { type: 'textTitl', text: "Push Nachrichten\nAlle unter Android installierten Apps sind standardmäßig für den Empfang von Push-Nachrichten freigeschaltet. Diese Push-Nachrichten nutzen wir um Dich über Updates oder Änderungen an Deinen Finanzverträgen zu informieren, so dass Du dann entscheiden kannst, ob Du etwas verändern willst oder nicht. Solltest Du keine Push-Nachrichten mehr bekommen wollen kannst Du diese jederzeit in den Einstellungen deaktivieren."},
    { type: 'subTitle', text: 'IOS (Apple)'},
    { type: 'textTitl', text: "Bei IOS von Apple ist es so, dass Du keine Berechtigungen bei Beginn Deiner Nutzung der App freigeben musst wie bei Android. Es kann aber sein, dass Du bei der Nutzung einzelner Features gefragt wirst, ob Du uns eine Berechtigung einräumen willst oder nicht. Das kann z.B. der Fall sein, wenn wir Dir eine Push-Nachricht zu einem Deiner Verträge schicken wollen oder Du ein Foto eines Versicherungsschadens in der App hochladen willst. Du kannst alle einmal erteilten Berechtigungen auch jederzeit in den Einstellungen wieder rückgängig machen."},
    { type: 'textTitl', text: "Server-Log-Files\nDer Provider unserer Seiten erhebt und speichert automatisch Informationen in sogenannten Server-Log Files, die Dein Browser automatisch an uns übermittelt. Dies sind:\nBrowsertyp und Browserversion\nverwendetes Betriebssystem\nReferrer URL\nHostname des zugreifenden Rechners\nUhrzeit der Serveranfrage"},
    { type: 'textTitl', text: "Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht von uns vorgenommen. Wir behalten uns jedoch vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden."},
    { type: 'textTitl', text: "Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 lit. f DSGVO. Die Speicherung ist erforderlich, um die Funktionsfähigkeit unserer App und die korrekte Darstellung der Inhalte zu gewährleisten. Weiterhin dienen die Daten unserer Statistik und der ständigen Optimierung unserer Inhalte. Schließlich erfolgt eine Speicherung, um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen zur Verfügung zu stellen."},
    { type: 'subTitle', text: 'Newsletterdaten'},
    { type: 'textTitl', text: "Auf unserer Internetseite myfeelix.de hast Du die Möglichkeit, den Newsletter unseres Unternehmens zu abonnieren. Welche personenbezogenen Daten bei der Bestellung des Newsletters an uns übermittelt und von uns gespeichert werden, ergibt sich aus der hierzu verwendeten Eingabemaske. Darüber hinaus werden zusätzlich die IP-Adresse sowie Datum und Uhrzeit der Übermittlung gespeichert. Für die Verarbeitung der Daten wird im Rahmen des Anmeldevorgangs Deine Einwilligung eingeholt. Rechtsgrundlage für die Verarbeitung ist daher Art. 6 Abs.1 lit. a DSGVO. Soweit es die Speicherung der IP-Adresse betrifft, ist Rechtsgrundlage zudem auch Art. 6 Abs.1 lit. f DSGVO. Es erfolgt keine Weitergabe der Daten an Dritte, sofern keine gesetzliche Offenbarungspflicht besteht.?\nDie Verarbeitung der von Dir durch Eingabe in das Anmeldeformular übermittelten Daten ist erforderlich, um den Newsletter zuzusenden. Eine darüber hinausgehende Nutzung erfolgt nicht. Die Speicherung der IP-Adresse ist notwendig, um eine missbräuchliche Verwendung (insbesondere der E-Mail-Adresse) zu verhindern bzw. aufzudecken und die Sicherheit unseres Systems sicherzustellen.\nDas Abonnement des Newsletters kann durch Dich jederzeit abbestellt und so die Einwilligung in den Versand widerrufen werden. Zu diesem Zweck wird in jeder versandten Newsletter-E-Mail ein entsprechender Link bereitgehalten. In diesem Fall – wie auch bei einem sonstigen, jederzeit möglichen Widerspruch gegen die Datennutzung – werden die personenbezogenen Daten, welche von uns im Rahmen des Newsletter-Abonnements gespeichert wurden, gelöscht. Die Daten werden demzufolge solange gespeichert, wie das Abonnement des Newsletters aktiv ist."},
    { type: 'textTitl', text: "Bei Versand von Newslettern erhebt wir statistische Daten. Dies geschieht anhand eines sogenannten Zählpixels. Hierbei handelt es sich um eine nicht sichtbare Miniaturgrafik, welche in die Newsletter-E-Mail eingebettet ist und mit Öffnen der E-Mail auf deinen Computer heruntergeladen wird. Gleiches gilt, wenn Du den in der E-Mail bereitgehaltene Link anklickt. Mit dem Download des Zählpixels werden der Zeitpunkt, die IP-Adresse des Nutzers und der verwendete Browsertyp an unseren Server übermittelt und gespeichert. Rechtsgrundlage hierfür ist Art. 6 Abs.1 lit. f. DSGVO.\nDie Verarbeitung der Daten dient der Optimierung des Newsletters. Sie ermöglicht eine statistische Auswertung, ob und wann eine E-Mail geöffnet wurde und welche in der E-Mail befindlichen Links aufgerufen wurden. Hierdurch können die Akzeptanz des Newsletters gemessen werden und entsprechende Anpassungen für zukünftige Newsletter sowohl in technischer als auch inhaltlicher Hinsicht vorgenommen werden, um eine bedarfsgerechte Nutzung zu optimieren. Es erfolgt keine Weitergabe der Daten an Dritte.\nDu kannst der Verarbeitung der Daten jederzeit dadurch widersprechen, dass Du den abonnierten Newsletter abbestellst (s. hierzu unter Abonnement unseres Newsletters). In diesem Fall werden personenbezogene Daten, welche uns in diesem Zusammenhang gespeichert wurden, gelöscht."},
    { type: 'subTitle', text: 'Datenschutzerklärung für die Nutzung von Google Analytics'},
    { type: 'textTitl', text: "Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Google Analytics verwendet so genannte \"Cookies\". Das sind Textdateien, die auf Deinem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Dich ermöglichen. Die durch den Cookie erzeugten Informationen über Deine Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics findest Du in der Datenschutzerklärung von Google: https://support.google.com/analytics/answer/6004245?hl=de"},
    { type: 'subTitle', text: 'Google Tag Manager und Google AdWords Conversion Tracking'},
    { type: 'textTitl', text: "Mittels \"AdWords Conversion-Tracking\" werden von uns definierte Kundenaktionen erfasst (wie beispielweise das Klicken auf eine Anzeige, Seitenaufrufe, Downloads) und analysiert. „AdWords Remarketing“ nutzen wir, um dir individualisierte Werbebotschaften für unsere Produkte auf Partnerwebsites von Google anzuzeigen. Beide Dienste setzten dafür Cookies und ähnliche Technologien ein."},
    { type: 'textTitl', text: "Falls du ein Google-Konto verwendest, kann Google je nach den im Google-Konto hinterlegten Einstellungen deinen Web- und App-Browserverlauf mit deinem Google-Konto verknüpfen und Informationen aus deinem Google-Konto zum Personalisieren von Anzeigen verwenden. Wenn du diese Zuordnung zu deinem Google-Konto nicht wünschst, ist es erforderlich, dass du dich vor dem Aufruf unserer Website bei Google ausloggst.\nDu kannst, wie oben dargestellt, deinen Browser so konfigurieren, dass er Cookies abweist. Zudem kannst du in den Google Einstellungen für Werbung die Schaltfläche „Personalisierte Werbung“ deaktivieren. In diesem Fall wird Google nur noch generelle Werbung anzeigen, die nicht anhand der über dich erfassten Informationen ausgewählt wurde."},
    { type: 'subTitle', text: 'Browser Plugin'},
    { type: 'textTitl', text: "Du kannst der Speicherung der Cookies durch eine entsprechende Einstellung Deiner Browser-Software verhindern; wir weisen Dich jedoch darauf hin, dass Du in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen kannst. Du kannst darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Deine Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Du das unter dem folgenden Link verfügbare Browser-Plugin herunterlädst und installierst: https://tools.google.com/dlpage/gaoptout?hl=de"},
    { type: 'subTitle', text: 'Auftragsdatenverarbeitung'},
    { type: 'textTitl', text: "Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um."},
    { type: 'subTitle', text: 'IP-Anonymisierung'},
    { type: 'textTitl', text: "Wir nutzen die Funktion \"Aktivierung der IP-Anonymisierung\" auf dieser Webseite. Dadurch wird Deine IP Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Deine Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Deinem Browser übermittelte IP Adresse wird nicht mit anderen Daten von Google zusammengeführt."},
    { type: 'subTitle', text: 'Datenschutzerklärung für die Nutzung von Google Web Fonts'},
    { type: 'textTitl', text: "Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in Deinen Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Wenn Dein Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Deinem Computer genutzt.\nWeitere Informationen zu Google Web Fonts findest Du unter https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google: https://www.google.com/policies/privacy/"},
    { type: 'subTitle', text: 'Datenschutzerklärung für die Nutzung von Google Maps'},
    { type: 'textTitl', text: "Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Zur Nutzung der Funktionen von Google Maps ist es notwendig, Deine IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Wir haben als  Anbieter dieser Seite keinen Einfluss auf diese Datenübertragung.\nMehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: https://www.google.de/intl/de/policies/privacy/"},
    { type: 'subTitle', text: 'Datenschutzerklärung für die Nutzung von YouTube'},
    { type: 'textTitl', text: "Unsere Webseite nutzt Plugins der von Google betriebenen Seite YouTube. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Du eine unserer mit einem YouTube-Plugin ausgestatteten Seiten besuchst, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem Youtube-Server mitgeteilt, welche unserer Seiten Du besucht hast. Wenn Du in Deinem YouTube-Account eingeloggt bist ermöglichst Du YouTube, Dein Surfverhalten direkt Deinem persönlichen Profil zuzuordnen. Dies kannst Du verhindern, indem Du Dich aus Deinem YouTube-Account ausloggst,  bevor Du unsere Webseiten besuchst.\nWeitere Informationen zum Umgang von Nutzerdaten finden Du in der Datenschutzerklärung von YouTube unter: https://www.google.de/intl/de/policies/privacy"},
    { type: 'subTitle', text: 'Datenschutzerklärung für die Nutzung des Google Pixels (Retargeting)'},
    { type: 'textTitl', text: "Zur Optimierung unseres Webangebotes sowie für Produktempfehlungen nutzen wir die Technologie der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“) zur Erstellung von pseudonymisierten Nutzungsprofilen über das Surfverhalten der Webseitenbesucher zu Marktingzwecken. Zu diesem Zweck können Cookies eingesetzt werden, die eine Wiedererkennung eines Internet-Browsers bei einem erneuten Besuch ermöglichen. Das gespeicherte Surfverhalten wird anhand eines Algorithmus analysiert, so dass anschließend gezielte interessenbezogene Produktempfehlungen im Form von Werbebanners bzw. Werbeanzeigen auf Webseiten Dritter angezeigt werden können. Die pseudonymisierten Nutzungsprofile werden ohne eine gesonderte ausdrückliche Einwilligung des Betroffenen nicht mit personenbezogenen Daten über den Träger des Pseudonyms zusammengeführt. Der Erstellung von pseudonymisierten Nutzungsprofilen für interessenbezogene Werbung/Anzeigenvorgaben können Sie jederzeit widersprechen, indem Sie die die Seite https://www.google.de/settings/ads aufrufen. Weitere Informationen zur interessenbezogenen Werbung erhalten Sie unter http://www.google.com/policies/technologies/ads/."},
    { type: 'subTitle', text: 'Datenschutzerklärung für die Nutzung des Facebook Pixel (Retargeting)'},
    { type: 'textTitl', text: "Unsere Webseite nutzt das Retargeting-Pixel Custom Audiences des sozialen Netzwerkes Facebook, 1601 South California Avenue, Palo Alto, CA 94304, USA. Mit Hilfe des Remarketing-Pixels ist es Facebook möglich, die Besucher unseres Internetauftritts als Zielgruppe für die Werbeanzeigen von Facebook-Ads zu nutzen. Zu diesem Zweck wird ein Facebook-Cookie auf Ihrem PC abgespeichert. Weitere Informationen über Umfang und Zweck der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzrichtlinien von Facebook unter https://facebook.com/policy.php und https://www.facebook.com/ads/settings. Der Nutzung von Custom Audiences können Sie unter www.youronlinechoices.com/de/praferenzmanagement oder für Nutzer mit einem Facebook-Konto hier (Verlinkung mit: https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2Fsettings%2F%3Ftab%3Dads) widersprechen."},
    { type: 'subTitle', text: 'Registrierung in der App'},
    { type: 'textTitl', text: "Du kannst Dich in unsere App registrieren, um zusätzliche Funktionen auf der App zu nutzen. Die Daten werden dabei von Dir in eine Eingabemaske eingegeben, an uns übermittelt und von uns gespeichert. Welche personenbezogenen Daten hiervon betroffen sind, ergibt sich aus der Eingabemaske. Darüber hinaus werden zusätzlich die IP-Adresse des Nutzers sowie Datum und Uhrzeit der Registrierung gespeichert. Am Ende des Registrierungsprozesses holen wir nochmals die Einwilligung des Nutzers zur Datenverarbeitung ein. Rechtsgrundlage für die Verarbeitung personenbezogener Daten ist daher insoweit Art. 6 Abs.1 lit. a DSGVO. Soweit es die Speicherung der IP-Adresse betrifft, ist Rechtsgrundlage zudem auch Art. 6 Abs.1 lit. f DSGVO. Es erfolgt keine Weitergabe der Daten an Dritte, sofern keine gesetzliche Offenbarungspflicht besteht."},
    { type: 'textTitl', text: "Als registrierter Nutzer hast Du jederzeit die Möglichkeit, die bei der Registrierung angegebenen personenbezogenen Daten abzuändern oder vollständig löschen zu lassen. Hierzu kannst Du die in Deinem Konto enthaltenen Funktionen verwenden oder uns Deinen Wunsch auf Änderung bzw. Löschung der Daten schriftlich oder per E-Mail mitteilen."},
    { type: 'textTitl', text: "Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei der Registrierung angegebene E-Mail-Adresse, um Dich auf diesem Wege zu informieren."},
    { type: 'subTitle', text: 'SSL-Verschlüsselung'},
    { type: 'textTitl', text: "Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Du an uns als Seitenbetreiber sendest, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennst Du daran, dass die Adresszeile des Browsers von \"http://\" auf \"https://\" wechselt und an dem Schloss-Symbol in Deiner Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Du an uns übermittelst, nicht von Dritten mitgelesen werden."},
    { type: 'subTitle', text: 'Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitalen Inhalten'},
    { type: 'textTitl', text: "Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte Kreditinstitut oder an eine Versicherung. Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Du der Übermittlung ausdrücklich zugestimmt hast. Eine Weitergabe Deiner Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht."},
    { type: 'subTitle', text: 'Vergleichsrechner / Online-Vergleichsberechnung'},
    { type: 'textTitl', text: "Auf unseren Webseiten werden Online-Vergleichsrechner von Dritt-Anbietern zur Nutzung angeboten, mit denen Du selbstständig Angebote berechnen kannst. Diese Dritt-Anbieter verstehen sich als Service- bzw. Geschäftspartner von uns und werden zum Zweck von Vergleichsberechnungen, dem Abschluss und/oder der Generierung von Deckungsnoten angeboten. Die Nutzung der Online-Vergleichsrechner über unsere Webseiten erfolgt ausschließlich freiwillig."},
    { type: 'textTitl', text: "Personenbezogene Daten, die Du im Rahmen einer Vergleichsberechnung oder eines Online-Abschlusses übermittelst, werden bei diesen Drittanbietern gespeichert, weiterverarbeitet und anschließend an uns zurückübertragen. Die eingegebenen Daten werden ausschließlich so lange gespeichert bis der Zweck erfüllt ist, zu dem Du uns bzw. dem Dritt-Anbieter Deine Daten anvertraut hast."},
    { type: 'catTitle', text: '03 Kinder und Jugendliche'},
    { type: 'textTitl', text: "Bist Du unter 18 Jahren, solltest Du ohne Zustimmung Deiner Eltern oder Erziehungsberechtigten keine personenbezogenen Daten an uns übermitteln. Werder fordern wir personenbezogenen Daten von Kindern und Jugendlichen an, noch sammeln wir solche Daten wissentlich."},
    { type: 'catTitle', text: '04 Kontaktaufnahme'},
    { type: 'textTitl', text: "Im Rahmen unserer Support- und Informationspflicht willigst Du mit den Datenschutzbestimmungen ein, dass wir regelmäßig und anlassbezogen Kontakt mit Dir aufnehmen können. Du erklärst Dich ausdrücklich damit einverstanden, dass wir Dich je nach Grund schriftlich, telefonisch, per SMS, per E-Mail und über unsere Chat-Applikation kontaktieren dürfen."},
    { type: 'catTitle', text: '05 Datenübermittlung'},
    { type: 'textTitl', text: "Bis zu Deinem schriftlichen Widerruf bist Du damit einverstanden, dass wir oder von uns beauftragte Dritte, insbesondere die genannten Kooperationspartner, sowie Versicherungen, Banken oder Bausparkassen und deren Tochtergesellschaften inkl. Maklerbetreuer zur Erfüllung der von uns geschuldeten Leistungen Unterlagen, Daten, Anfragen usw. per Email übermittelt. Die Übermittlung dient nur der Erfüllung bestehender Vertragsverhältnisse.\nGesundheitsdaten dürfen nur vertraulich an Personen und Rückversicherer übermittelt werden. Soweit es zur Vertragsgestaltung notwendig ist, dürfen diese auch an uns weitergegeben werden."},
    { type: 'catTitle', text: '06 Deine Rechte als betroffene Person'},
    { type: 'textTitl', text: "Werden personenbezogene Daten von Dir verarbeitet, bist Du betroffene Person i.S.d. DSGVO und es stehen Dir die folgende Rechte gegenüber uns als Verantwortlichem zu:"},
    { type: 'subTitle', text: 'a) Recht auf Bestätigung und Auskunft'},
    { type: 'textTitl', text: "Du kannst von uns jederzeit eine Bestätigung verlangen, ob personenbezogene Daten, die Dich betreffen, von uns verarbeitet werden. Ist dies der Fall, hast du einen Auskunftsanspruch, von uns über folgende Umstände in Kenntnis gesetzt zu werden:\n1.) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;\n2.) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Dich betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;\n3.) die geplante Dauer der Speicherung der Dich betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;\n4.) das Bestehen eines Rechts auf Berichtigung oder Löschung der Dich betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;\n5.) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;\n6.) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;\n7.) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.\n\nDarüber hinaus steht Dir ein Auskunftsrecht zu, ob personenbezogene Daten in einen Staat, der nicht Mitglied der EU ist (sog. Drittland), oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang kannst Du verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden."},
    { type: 'subTitle', text: 'b) Recht auf Berichtigung'},
    { type: 'textTitl', text: "Du hast das Recht, die unverzügliche Berichtigung bezüglich Deiner betreffender unrichtiger personenbezogener Daten von uns zu verlangen. Ferner steht Dir das Recht zu, von uns unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten — auch mittels einer ergänzenden Erklärung — zu verlangen."},
    { type: 'subTitle', text: 'c) Recht auf Löschung (Recht auf Vergessen werden)'},
    { type: 'textTitl', text: "Du kannst von uns verlangen, dass die Dich betreffenden personenbezogenen Daten unverzüglich gelöscht werden, wenn einer der folgenden Gründe vorliegt:\n1.) Die Dich betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.\n2.) Du widerrufst Deine Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.\n3.) Du legst gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor oder Du legst gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.\n4.) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.\n5.) Die Löschung der Dich betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.\n6.) Die Dich betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben."},
    { type: 'textTitl', text: "Wurden die Dich betreffenden personenbezogenen Daten von uns öffentlich gemacht und sind wir nach obenstehenden Grundsätzen zur Löschung der personenbezogenen Daten verpflichtet, so trifft uns ebenfalls die Pflicht andere für die Datenverarbeitung Verantwortliche darüber in Kenntnis zu setzen, dass Du als betroffene Person die Löschung sämtlicher Links zu deinen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.\nWir ergreifen diesbezüglich, unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um diesen Pflichten nachzukommen, jedenfalls soweit die Verarbeitung nicht weiterhin erforderlich ist, also gesetzliche Vorgaben dies vorschreiben oder berechtigte Interessen der Löschung entgegenstehen."},
    { type: 'subTitle', text: 'd) Recht auf Einschränkung der Verarbeitung'},
    { type: 'textTitl', text: "Du kannst unter den folgenden Voraussetzungen von uns die Einschränkung der Verarbeitung der Dich betreffenden personenbezogenen Daten verlangen:"},
    { type: 'textTitl', text: "1. Die Richtigkeit der personenbezogenen Daten wird von Dir bestritten, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen.\n2. Die Verarbeitung ist unrechtmäßig und Du verlangst anstatt einer Löschung die Einschränkung der Nutzung der personenbezogenen Daten.\n3. Die personenbezogenen Daten werden von uns nicht länger für die Zwecke der Verarbeitung benötigt, jedoch benötigst Du diese Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.\n4. Du hast Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DSGVO eingelegt und es steht noch nicht fest, ob die berechtigten Gründe uns gegenüber Deinen Gründen überwiegen."},
    { type: 'textTitl', text: "Wurde die Verarbeitung der Dich betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Deiner Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden. In diesem Fall wirst Du zudem von uns unterrichtet, bevor die Einschränkung aufgehoben wird."},
    { type: 'subTitle', text: 'e) Recht auf Unterrichtung'},
    { type: 'textTitl', text: "Hast Du das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung geltend gemacht, sind wir verpflichtet, allen Empfängern, denen die Dich betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Insoweit kannst Du von uns verlangen, über diese Empfänger unterrichtet zu werden."},
    { type: 'subTitle', text: 'f) Recht auf Datenübertragbarkeit ("Datenportabilität")'},
    { type: 'textTitl', text: "Du hast das Recht, die Dich betreffenden personenbezogenen Daten, welche Du uns zur Verfügung gestellt hast, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Des Weiteren hast Du das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art. 9 Abs. 2 Buchstabe a DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde."},
    { type: 'textTitl', text: "Ferner kannst Du bei der Ausübung Deines Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DSGVO verlangen, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hierdurch keine Rechte und Freiheiten anderer Personen beeinträchtigt werden."},
    { type: 'subTitle', text: 'g) Widerspruchsrecht'},
    { type: 'textTitl', text: "Du hast das Recht, aus Gründen, die sich aus Deiner besonderen Situation ergeben, von uns jederzeit gegen die Verarbeitung Dich betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.\nWir verarbeiten die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.\nWerden die Dich betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Du das Recht, jederzeit Widerspruch gegen die Verarbeitung der Dich betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widersprichst Du der Verarbeitung für Zwecke der Direktwerbung, so werden die Dich betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.\nDu hast die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Dein Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden."},
    { type: 'subTitle', text: 'h) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung'},
    { type: 'textTitl', text: "Sofern Du eine datenschutzrechtliche Einwilligung erteilt hast, steht Dir das Recht zu, diese Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen."},
    { type: 'subTitle', text: 'i) Automatisierte Entscheidungen im Einzelfall einschließlich Profiling'},
    { type: 'textTitl', text: "Du hast das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung\n— einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden, die Dir gegenüber rechtliche Wirkung entfaltet oder Dich in ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung\n1. nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen Dir und feelix erforderlich ist\noder\n2. aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person enthalten\noder\n3. mit ausdrücklicher Einwilligung der betroffenen Person erfolgt."},
    { type: 'textTitl', text: "Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Deiner berechtigten Interessen getroffen wurden.\nIst die Entscheidung für den Abschluss oder die Erfüllung eines Vertrags zwischen Dir und dem Verantwortlichen erforderlich oder erfolgt sie mit Deiner ausdrücklicher Einwilligung, trifft feelix angemessene Maßnahmen, um die Rechte und Freiheiten sowie Deine berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört."},
    { type: 'subTitle', text: 'j) Recht auf Beschwerde bei der Aufsichtsbehörde'},
    { type: 'textTitl', text: "Ungeachtet der gegenüber uns bestehenden Rechte, steht Dir auch das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem MitgliedstaatDeines Aufenthaltsorts, Deines Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Du der Ansicht bist, dass die Verarbeitung der Dich betreffenden personenbezogenen Daten gegen die DSGVO verstößt.\nDie Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet Dich über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO."},
    { type: 'textTitl', text: "Sollte eine Regelung dieser Erklärung unwirksam sein oder durch die Rechtsprechung oder durch gesetzliche Regelungen unwirksam werden, so hat dies nicht die Unwirksamkeit der gesamten Einwilligung zur Folge. Die nichtige (unwirksame) Bestimmung ist dann durch eine Regelung zu ersetzen, die dem angestrebten Zweck am ehesten entspricht."},
    { type: 'textTitl', text: "Diese Einwilligung der Datenschutzbestimmungen kannst Du jederzeit ohne Angabe von Gründen formlos gegenüber der feelix GmbH widerrufen. Solltest Du Fragen zu unseren Datenschutzhinweisen haben oder wenn Du diesbezüglich Anmerkungen machen möchtest, steht Dir unser Datenschutzbeauftragter unter datenschutz@myfeelix.de jederzeit gerne zur Verfügung."},
    { type: 'textTitl', text: "Bis zu Deinem schriftlichen Widerruf bist Du damit einverstanden, dass wir oder von uns beauftragte Dritte, insbesondere die genannten Kooperationspartner, sowie Versicherungen, Banken oder Bausparkassen und deren Tochtergesellschaften inkl. Maklerbetreuer zur Erfüllung der von uns geschuldeten Leistungen Unterlagen, Daten, Anfragen usw. per Email übermittelt. Die Übermittlung dient nur der Erfüllung bestehender Vertragsverhältnisse."},
    { type: 'textTitl', text: "Gesundheitsdaten dürfen nur vertraulich an Personen und Rückversicherer übermittelt werden. Soweit es zur Vertragsgestaltung notwendig ist, dürfen diese auch an uns weitergegeben werden."},
    { type: 'catTitle', text: '07 Archivierung'},
    { type: 'textTitl', text: "Du willigst mit den Datenschutzbestimmungen weiterhin ein, dass die Versicherer einschließlich der Maklerbetreuer und die genannten Kooperationspartner, soweit dies zur ordnungsgemäßen Durchführung der Versicherungsangelegenheiten gehört, die allgemeinen Antrags-, Vertrags-, Abrechnungs- und Leistungsdaten in gemeinsamen Datensammlungen führen – das auch für Archivierungszwecke gilt – und an die feelix GmbH weitergeben."},
    { type: 'textTitl', text: "Du willigst ferner ein, dass Deine Personalien und Deine Kontoverbindung bei uns zum Zweck der Kundenbetreuung gespeichert werden. Wir dürfen Dich darüber hinaus auch in anderen Produktsparten beraten, dazu kontaktieren und weitere Produktvorschläge zu unterbreiten."},
    { type: 'catTitle', text: '08 Vertragspartnerwechsel'},
    { type: 'textTitl', text: "Sollte wir unseren Geschäftsbetrieb inklusive erteilter Maklervollmachten für Finanzverträge, z.B. Versicherungen, Bankkredite, Depotkonten oder andere, ganz oder teilweise auf einen anderen Vertragspartner übertragen (z. B. im Rahmen der Veräußerung des Geschäftsbetriebes), bist Du mit dem Eintritt des anderen Vertragspartners in die bestehenden Maklervollmacht und der Weitergabe Deiner Vertrags- und Leistungsdaten aus den Finanzverträgen bei Einhaltung des folgenden Verfahrens einverstanden:"},
    { type: 'textTitl', text: "Der neue Vertragspartner unterrichtet Dich vor Durchführung einer beabsichtigten Vertragsübernahme und Datenweitergabe in Textform über die vorgesehenen Maßnahmen und räumt Dir die Möglichkeit ein, der Vertragsübernahme und Datenweitergabe binnen einer Frist von einem Monat ab Zugang der Information in Textform zu widersprechen. In der Mitteilung erhältst Du auch den Namen und Anschrift des übernehmenden Vertragspartners."},
    { type: 'textTitl', text: "Widersprichst Du den mitgeteilten Maßnahmen nicht innerhalb der Monatsfrist, kann der neue Vertragspartner davon ausgehen, dass Du mit dem Eintritt des Erwerbers in die Maklervollmacht, den jeweilig in Betreuung befindlichen Finanzverträgen und mit der Datenweitergabe an diesen einverstanden bist. Die Vertragsübernahme und Datenweitergabe wird dann entsprechend vollzogen."},
    { type: 'catTitle', text: '09  Daten nach dem Geldwäschegesetz'},
    { type: 'textTitl', text: "Du erklärst hiermit wirtschaftlich Berechtigter im Sinne des § 3 Abs. 1 Nr. 2 GWG bezüglich der von Dir angegebenen Versicherungsverhältnisse zu sein.\nWir sind als Verpflichteter gemäß § 2 Abs. 11 GWG dazu angehalten im Hinblick auf eventuelle Geldwäsche- und Terrorismusfinanzierungstaten gewisse Daten zu erheben und diese im Falle eines Verdachts der Geldwäsche oder der Terrorismusfinanzierung an die zuständige Aufsichtsbehörde, die Zentralstelle für Finanztransaktionsuntersuchungen, gemäß § 43 GWG zu übermitteln. Die zu diesem Zweck zu erhebenden und zu speichernden Daten betreffen insbesondere solche Daten, die Deine Identifizierung ermöglichen (Vorname, Nachname, Geburtsort, Geburtsdatum, Staatsangehörigkeit, Wohnanschrift). Ferner besteht eine Aufzeichnungspflicht bezüglich der Transaktionen, die wir Dir gegenüber durchführen, sowie bezüglich der intern durchgeführten Risikoanalyse und dessen Ergebnisse (inklusive Erwägungsgründe und Begründung) bei Auftreten eines Verdachts der Geldwäsche und/ oder Terrorismusfinanzierung.\nDiese Daten werden gemäß § 8 Abs. 4 GWG fünf Jahre, beginnend mit dem Ende der Geschäftsbeziehung, aufbewahrt und nach Ablauf dieser Frist gelöscht."},
    { type: 'catTitle', text: '10 Änderungen der Datenschutzerklärung'},
    { type: 'textTitl', text: "Durch Marktveränderungen, neue Regularien oder durch die Weiterentwicklung unserer App kann es notwendig werden, dass wir unsere Datenschutzerklärung anpassen müssen. Daher behalten wir uns vor diese Datenschutzvereinbarung zu ändern. Du kannst jederzeit in der App die aktuellste Fassung der Datenschutzerklärung abrufen."},
    //infoAdvisement
    { type: 'secTitle', text: 'Erstinformationen'},
    { type: 'textTitl', text: "feelix GmbH\nInnsbrucker Str. 38\n10825\nBerlin\nVertretungsberechtigter Geschäftsführer: Tilo Hammer"},
    { type: 'textTitl', text: "Registergericht Amtsgericht Berlin-Charlottenburg\nRegisternummer\nHRB 145006 B\nSteuernummer\n29/443/10274"},
    { type: 'textTitl', text: "E-Mail info@myfeelix.de\nTelefon +49 (0)30 – 577 00 5730\nTelefax +49 (0)30 – 577 00 5731"},
    { type: 'textTitl', text: "Du beauftragst die feelix GmbH mit der Vermittlung von Versicherungsverträgen, einschließlich der Vorbereitungen sowie nach Abschluss dieser Verträge mit der Betreuung und Verwaltung, insbesondere der Mitwirkung bei der Schadenregulierung. Bei Abschluss dieser Vereinbarung werden bereits bestehende Versicherungs- und Finanzverträge nur mit einbezogen, falls diese der feelix GmbH bekannt sind und wenn dies ausdrücklich vereinbart wird."},
    { type: 'textTitl', text: "Status der feelix GmbH:"},
    { type: 'textTitl', text: "1. Versicherungsmakler nach § 34d Abs. 1 der Gewerbeordnung und im Vermittlerregister unter der Nummer D-WS9L-N517A-11 registriert."},
    { type: 'textTitl', text: "2. Finanzanlagenvermittler mit einer Erlaubnis nach § 34f Absatz 1, Satz 1, Nr. 1, 2 und 3 der Gewerbeordnung und bei der Erlaubnisbehörde BA Fried­richs­hain-Kreuz­berg des Ber­liner Ord­nungs- und Ge­wer­be­amtes unter der Registrierungsnummer D-F-107-8V32-07 eingetragen."},
    { type: 'textTitl', text: "3. Immobilien Darlehensvermittler mit einer Erlaubnis nach § 11a Abs. 1 GewO und bei der Erlaubnisbehörde BA Fried­richs­hain-Kreuz­berg des Ber­lin Ord­nungs- und Ge­wer­be­amtes unter der Registrierungsnummer D-W-107-UXX5-42 eingetragen."},
    { type: 'textTitl', text: "Registerstelle des Vermittler-Registers:\nDeutscher Industrie- und Handelskammertag e.V.\nBreite Straße 29\n10178 Berlin\nAuskunft über Tel.: 030 20308-0 oder www.vermittlerregister.org"},
    { type: 'subTitle', text: 'Teilnahme am Streitbeilegungsverfahren gemäß § 36 Verbraucherstreitbeilegungsgesetz'},
    { type: 'textTitl', text: "Wir sind bereit, an einem Streitbeilegungsverfahren vor folgenden Verbraucherschlichtungsstellen teilzunehmen:"},
    { type: 'textTitl', text: "1. Versicherungsombudsmann e.V. - Postfach 08 06 32 - 10006 Berlin - www.versicherungsombudsmann.de\n2. Ombudsmann private Kranken- und Pflegeversicherung - Postfach 06 02 22 - 10052 Berlin - www.pkv-ombudsmann.de\n3. Allgemeine Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V. - Straßburger Str. 8 - 77694 Kehl - www.verbraucher-schlichter.de“"},
    { type: 'textTitl', text: "Die feelix GmbH besitzt keine direkte oder indirekte Beteiligung von über zehn Prozent an den Stimmrechten oder am Kapital eines Versicherungsunternehmens. Kein Versicherungsunternehmen oder Mutterunternehmen eines Versicherungsunternehmens besitzt eine direkte oder indirekte Beteiligung von über zehn Prozent an den Stimmrechten bzw. am Kapital der feelix GmbH"},
    { type: 'textTitl', text: "Bei Beschwerden kannst Du Dich direkt an folgende Adresse wenden:\nSchlichtungsstelle der Deutschen Bundesbank,\nPostfach 11 12 32\n60047 Frankfurt am Main"},
    { type: 'subTitle', text: 'Emittenten und Anbieter, zu deren Finanzanlagen Vermittlungs- oder Beratungsleistungen angeboten werden'},
    { type: 'textTitl', text: "Wir vermitteln und beraten aktuell zu 240 Emittenten und Anbietern:"},
    { type: 'subTitle', text: 'A'},
    { type: 'textSubT', text: "ACM Bernstein"},
    { type: 'textSubT', text: "Allianz Lebensversicherungs AG"},
    { type: 'textSubT', text: "Allianz Private Krankenversicherungs AG"},
    { type: 'textSubT', text: "Allianz Versicherung AG"},
    { type: 'textSubT', text: "Alte Leipziger Bauspar AG"},
    { type: 'textSubT', text: "ALTE LEIPZIGER Lebensversicherung a.G."},
    { type: 'textSubT', text: "Alte Leipziger Versicherung AG"},
    { type: 'textSubT', text: "Alte Oldenburger Krankenversicherung Versicherungsverein a.G."},
    { type: 'textSubT', text: "Ammerländer Versicherung"},
    { type: 'textSubT', text: "ARAG Allgemeine Rechtsschutz-Versicherungs-AG"},
    { type: 'textSubT', text: "ARAG Allgemeine Versicherungs-AG"},
    { type: 'textSubT', text: "ARAG Krankenversicherungs-AG"},
    { type: 'textSubT', text: "ARAG Lebensversicherungs-AG"},
    { type: 'textSubT', text: "ASPECTA Lebensversicherungs-AG"},
    { type: 'textSubT', text: "Augsburger Aktienbank AG"},
    { type: 'textSubT', text: "AUXILIA Rechtsschutz-Versicherungs-AG"},
    { type: 'textSubT', text: "Auxilia Versicherungs AG"},
    { type: 'textSubT', text: "AXA Krankenversicherung AG"},
    { type: 'textSubT', text: "AXA Lebensversicherung AG"},
    { type: 'textTitl', text: "AXA Versicherung AG"},
    { type: 'subTitle', text: 'B'},
    { type: 'textSubT', text: "Baloise Life (Liechtenstein) AG"},
    { type: 'textSubT', text: "Barmenia Allgemeine Versicherungs-AG"},
    { type: 'textSubT', text: "Barmenia Krankenversicherung a.G."},
    { type: 'textSubT', text: "Barmenia Lebensversicherung a.G."},
    { type: 'textSubT', text: "Bayern-Versicherung Lebensversicherung AG"},
    { type: 'textTitl', text: "BHW Bausparkasse AG"},
    { type: 'subTitle', text: 'C'},
    { type: 'textSubT', text: "Canada Life Assurance Europe Ltd."},
    { type: 'textSubT', text: "cash.life AG"},
    { type: 'textSubT', text: "Central Krankenversicherung AG"},
    { type: 'textSubT', text: "comdirect bank AG"},
    { type: 'textSubT', text: "COMMERZBANK AG"},
    { type: 'textSubT', text: "Concordia Krankenversicherungs-AG"},
    { type: 'textSubT', text: "Concordia Lebensversicherungs-AG"},
    { type: 'textSubT', text: "Concordia Rechtsschutz-Versicherungs-AG"},
    { type: 'textSubT', text: "Concordia Versicherungs-Gesellschaft a.G."},
    { type: 'textSubT', text: "Condor Allgemeine Versicherungs-AG"},
    { type: 'textSubT', text: "Condor Lebensversicherungs AG"},
    { type: 'textSubT', text: "Continentale Krankenversicherung a.G."},
    { type: 'textSubT', text: "Continentale Lebensversicherung AG"},
    { type: 'textSubT', text: "Continentale Sachversicherung AG"},
    { type: 'textSubT', text: "Cortal Consors"},
    { type: 'textTitl', text: "CSS Versicherung AG"},
    { type: 'subTitle', text: 'D'},
    { type: 'textSubT', text: "DAB Bank AG"},
    { type: 'textSubT', text: "DBV Winterthur Lebensversicherung AG"},
    { type: 'textSubT', text: "Deutsche Bank AG"},
    { type: 'textSubT', text: "Deutsche Kreditbank"},
    { type: 'textSubT', text: "Deutscher Ring Bausparkasse AG"},
    { type: 'textSubT', text: "Deutscher Ring Krankenversicherung a.G."},
    { type: 'textSubT', text: "Deutscher Ring Lebensversicherungs AG"},
    { type: 'textSubT', text: "Deutscher Ring Sachversicherungs-AG"},
    { type: 'textSubT', text: "DKV Deutsche Krankenversicherung AG"},
    { type: 'textSubT', text: "DLVAG"},
    { type: 'textSubT', text: "DSL Bank AG"},
    { type: 'textTitl', text: "DWS Investment GmbH"},
    { type: 'subTitle', text: 'E'},
    { type: 'textSubT', text: "ebase GmbH"},
    { type: 'textSubT', text: "EUROPA Lebensversicherung AG"},
    { type: 'textTitl', text: "EUROPA Versicherung AG"},
    { type: 'subTitle', text: 'F'},
    { type: 'textSubT', text: "Fondsdepot Bank GmbH"},
    { type: 'textSubT', text: "Fondskonzept AG"},
    { type: 'textSubT', text: "FondsServiceBank"},
    { type: 'textTitl', text: "Frankfurter Fondsbank GmbH"},
    { type: 'subTitle', text: 'G'},
    { type: 'textSubT', text: "Gothaer Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "Gothaer Krankenversicherung AG"},
    { type: 'textTitl', text: "Gothaer Lebensversicherung AG"},
    { type: 'subTitle', text: 'H'},
    { type: 'textSubT', text: "Haftpflichtkasse Darmstadt"},
    { type: 'textSubT', text: "Hallesche Krankenversicherung a.G."},
    { type: 'textSubT', text: "HanseMerkur Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "HanseMerkur Krankenversicherung a.G."},
    { type: 'textSubT', text: "HanseMerkur Lebensversicherung AG"},
    { type: 'textSubT', text: "HanseMerkur Reiseversicherung AG"},
    { type: 'textTitl', text: "HDI-Gerling Firmen und Privat Versicherung AG"},
    { type: 'subTitle', text: 'I'},
    { type: 'textSubT', text: "ING-Diba AG"},
    { type: 'textSubT', text: "INTER Krankenversicherung aG"},
    { type: 'textSubT', text: "INTER Lebensversicherung AG"},
    { type: 'textTitl', text: "Interrisk Versicherung AG"},
    { type: 'subTitle', text: 'J'},
    { type: 'textTitl', text: "Janitos Versicherung AG"},
    { type: 'subTitle', text: 'K'},
    { type: 'textTitl', text: "Karlsruher Lebensversicherung AG"},
    { type: 'subTitle', text: 'L'},
    { type: 'textSubT', text: "Lebensversicherung von 1871 a.G. M"},
    { type: 'textTitl', text: " LVM Versicherung"},
    { type: 'subTitle', text: 'M'},
    { type: 'textSubT', text: "Moventum"},
    { type: 'textTitl', text: "Münchener Verein Krankenversicherung"},
    { type: 'subTitle', text: 'N'},
    { type: 'textSubT', text: "Nürnberger Lebensversicherung AG"},
    { type: 'textTitl', text: "Nürnberger Versicherung AG"},
    { type: 'subTitle', text: 'O'},
    { type: 'textTitl', text: "One Consulting GmbH"},
    { type: 'subTitle', text: 'P'},
    { type: 'textSubT', text: "Project Vermittlungs GmbH"},
    { type: 'textTitl', text: "PSD Berlin Brandenburg"},
    { type: 'subTitle', text: 'R'},
    { type: 'textTitl', text: "ROLAND Rechtsschutz-Versicherungs-AG"},
    { type: 'subTitle', text: 'S'},
    { type: 'textSubT', text: "SIGNAL IDUNA Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "SIGNAL IDUNA Bauspar AG"},
    { type: 'textSubT', text: "SIGNAL IDUNA Pensionskasse AG"},
    { type: 'textSubT', text: "SIGNAL Krankenversicherung a.G."},
    { type: 'textSubT', text: "Skandia Lebensversicherung AG"},
    { type: 'textSubT', text: "Sparda Bank Berlin eG"},
    { type: 'textSubT', text: "Stuttgarter Lebensversicherung a.G."},
    { type: 'textSubT', text: "Stuttgarter Versicherung AG"},
    { type: 'textTitl', text: "Swiss Life AG"},
    { type: 'subTitle', text: 'U'},
    { type: 'textSubT', text: "uniVersa Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "uniVersa Krankenversicherung a.G."},
    { type: 'textTitl', text: "uniVersa Lebensversicherung a.G."},
    { type: 'subTitle', text: 'V'},
    { type: 'textSubT', text: "Volkswohl-Bund Lebensversicherung a.G."},
    { type: 'textTitl', text: "VPV Lebensversicherungs-AG"},
    { type: 'subTitle', text: 'W'},
    { type: 'textSubT', text: "WWK Allgemeine Versicherung AG"},
    { type: 'textTitl', text: "WWK Lebensversicherung a.G."},
    { type: 'subTitle', text: 'Z'},
    { type: 'textSubT', text: "Zurich Deutscher Herold Lebensversicherung AG"},
    { type: 'textTitl', text: "Zurich Versicherung AG"},
    { type: 'textTitl', text: "http://www.myfeelix.de/feelix-kooperationspartner/"},
    { type: 'subsubTi', text: 'Vergütung bei der Finanzanlagenberatung und –vermittlung'},
    { type: 'textTitl', text: "Im Zusammenhang mit der Anlageberatung oder -vermittlung kann die Vergütung hierfür durch Dich als Anleger oder durch Dritte (Produktanbieter) in Kombination erfolgen. Dies ist abhängig von Deinen Wünschen und Bedürfnissen des Anlegers und den Finanzprodukten, welche eventuell vermittelt werden."},
    { type: 'textTitl', text: "Soweit die Vergütungsbestandteile insofern durch Dich als Anleger gezahlt werden, erfolgt dies entsprechend der gesondert zu treffenden Vergütungsvereinbarung."},
    { type: 'textTitl', text: "Soweit Zuwendungen im Zusammenhang mit der Anlageberatung oder -vermittlung insofern von Dritten (Produktanbietern) erbracht werden, dürfen diese behalten werden."},
    { type: 'subsubTi', text: 'Ergänzende Angaben zur Erstinformation für Immobiliar-Verbraucherdarlehensvermittler\nLeistungsentgelt/ Kosten'},
    { type: 'textTitl', text: "Der Vermittler erhält ein Leistungsentgelt für die erfolgreiche Vermittlung vom Darlehensgeber"},
    { type: 'subsubTi', text: 'Produktauswahlpalette'},
    { type: 'textTitl', text: "Der Vermittler wird unabhängig tätig."},
    { type: 'subsubTi', text: 'Beratungsleistung'},
    { type: 'textTitl', text: "Der Vermittler erbringt Beratungsleistungen."},
    { type: 'subsubTi', text: 'Beschwerden und Streitschlichtung'},
    { type: 'textTitl', text: "Bei Beschwerden können Sie sich direkt an die"},
    { type: 'textTitl', text: "Schlichtungstelle der Deutschen Bundesbank\nPostfach 11 12 32\n60047 Frankfurt am Main"},
    { type: 'textTitl', text: "wenden."},
    //infoImprint
    { type: 'secTitle', text: 'Impressum'},
    { type: 'textTitl', text: "Angaben gemäß § 5 TMG:\n\n\n"},
    { type: 'textTitl', text: "feelix GmbH\nMethfesselstr. 50\n10965 Berlin\n\n\n"},
    { type: 'textTitl', text: "Vertreten durch:\nGeschäftsführer Tilo Hammer\n\n\n"},
    { type: 'textTitl', text: "Kontakt:\nTelefon: +49 30 577 005 730\nTelefax: +49 30 577 005 731\nE-Mail: info@myfeelix.de\n\n\n"},
    { type: 'subsubTi', text: 'Registereintrag:'},
    { type: 'textTitl', text: "Eintragung im Handelsregister.\nRegistergericht:Amtsgericht Berlin-Charlottenburg\nRegisternummer: HRB 145006 B\n\n\n"},
    { type: 'textTitl', text: "Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:\nDE286303982\n\n\n"},
    { type: 'subsubTi', text: 'Aufsichtsbehörde:'},
    { type: 'textTitl', text: "IHK Berlin, Fasanenstraße 85, 10623 Berlin\n\n\n"},
    { type: 'textTitl', text: "Eingetragen als feelix GmbH im Vermittlerregister unter der Register Nr: D-WS9L-N517A-11\nErlaubnis nach §34d Abs. 1 GewO (Versicherungsmakler)\n\n\n"},
    { type: 'textTitl', text: "Eingetragen als feelix GmbH im Vermittlerregister unter der Register Nr: D-F-107-8V32-07\nErlaubnis nach §34f Abs. 1 Ziff. 11 GewO (Finanzanlagenvermittler)\nErlaubnisbehörde: BA Fried­richs­hain-Kreuz­berg des Ber­liner Ord­nungs- und Ge­wer­be­amtes\n\n\n"},
    { type: 'textTitl', text: "Eingetragen als feelix GmbH im Vermittlerregister unter der Register Nr: D-W-107-UXX5-42\nErlaubnis nach §11a Abs. 1 GewO (Immobiliendarlehensvermittler)\nErlaubnisbehörde: BA Fried­richs­hain-Kreuz­berg des Ber­liner Ord­nungs- und Ge­wer­be­amtes\n\n\n"},
    { type: 'subsubTi', text: 'Berufsbezeichnung:'},
    { type: 'textTitl', text: "Versicherungsmakler mit Erlaubnis nach §34d Abs. 1 GewO\nFinanzanlagenvermittler mit Erlaubnis nach §34f Abs. 1 GewO\nImmobiliendarlehensvermittler mit Erlaubnis §11a Abs. 1 GewO\n\n\n"},
    { type: 'subsubTi', text: 'Berufsrechtliche Regelungen:'},
    { type: 'textTitl', text: "§ 34d Gewerbeordnung\n§ 34f Gewerbeordnung\n§ 11a Gewerbeordnung\n§§ 59 - 68 Versicherungsvertragsgesetz\nVersicherungs-Vermittler-Verordnung\n\n\n"},
    { type: 'subsubTi', text: 'Angaben zur Vermögensschadenshaftpflicht'},
    { type: 'textTitl', text: "Name und Sitz des Versicherung:\nR&V Allgemeine Versicherung AG\nRaiffeisenplatz 1\n65189 Wiesbaden\nGeltungsraum der Versicherung: Deutschland\n\n\n"},
    { type: 'subsubTi', text: 'Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:'},
    { type: 'textTitl', text: "Tilo Hammer\nMethfesselstr. 50\n10965 Berlin\n\n\n"},
    { type: 'textTitl', text: "Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:\nhttp://ec.europa.eu/consumers/odr\nUnsere E-Mail-Adresse finden Sie oben im Impressum.\nWir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Die zuständige Verbraucherschlichtungsstelle ist:\nAnschriften von Schlichtungsstellen für Probleme im Zusammenhang mit einer privaten Kranken- oder Pflegeversicherung:\nOmbudsmann Private Krankenversicherung Postfach 06 02 22 · D-10052 Berlin\nTelefon +49 (0) 180 2 550 444 Telefax +49 (0) 30 20 458 931\nWeitere Informationen auf www.pkv-ombudsmann.de.\n\n\n"},
    { type: 'textTitl', text: "In allen anderen Fällen Versicherungsombudsmann e.V. Postfach 08 06 32 · D-10006 Berlin\nTelefon +49 (0) 800 · 369 6000 Telefax +49 (0) 800 · 369 9000\bhttp://www.versicherungsombudsmann.de\n\n\n"},
    { type: 'subsubTi', text: 'Haftung für Inhalte'},
    { type: 'textTitl', text: "Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.\n\n\n"},
    { type: 'subsubTi', text: 'Haftung für Links'},
    { type: 'textTitl', text: "Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.\n\n\n"},
    { type: 'subsubTi', text: 'Urheberrecht'},
    { type: 'textTitl', text: "Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.\n\n\n"},
    //infoProductPartners
    { type: 'secTitle', text: 'Produktpartner'},
    { type: 'subTitle', text: 'Emittenten und Anbieter, zu deren Finanzanlagen Vermittlungs- oder Beratungsleistungen angeboten werden'},
    { type: 'textTitl', text: "Wir vermitteln und beraten aktuell zu 240 Emittenten und Anbietern:"},
    { type: 'subTitle', text: 'A'},
    { type: 'textSubT', text: "ACM Bernstein"},
    { type: 'textSubT', text: "Allianz Lebensversicherungs AG"},
    { type: 'textSubT', text: "Allianz Private Krankenversicherungs AG"},
    { type: 'textSubT', text: "Allianz Versicherung AG"},
    { type: 'textSubT', text: "Alte Leipziger Bauspar AG"},
    { type: 'textSubT', text: "ALTE LEIPZIGER Lebensversicherung a.G."},
    { type: 'textSubT', text: "Alte Leipziger Versicherung AG"},
    { type: 'textSubT', text: "Alte Oldenburger Krankenversicherung Versicherungsverein a.G."},
    { type: 'textSubT', text: "Ammerländer Versicherung"},
    { type: 'textSubT', text: "ARAG Allgemeine Rechtsschutz-Versicherungs-AG"},
    { type: 'textSubT', text: "ARAG Allgemeine Versicherungs-AG"},
    { type: 'textSubT', text: "ARAG Krankenversicherungs-AG"},
    { type: 'textSubT', text: "ARAG Lebensversicherungs-AG"},
    { type: 'textSubT', text: "ASPECTA Lebensversicherungs-AG"},
    { type: 'textSubT', text: "Augsburger Aktienbank AG"},
    { type: 'textSubT', text: "AUXILIA Rechtsschutz-Versicherungs-AG"},
    { type: 'textSubT', text: "Auxilia Versicherungs AG"},
    { type: 'textSubT', text: "AXA Krankenversicherung AG"},
    { type: 'textSubT', text: "AXA Lebensversicherung AG"},
    { type: 'textTitl', text: "AXA Versicherung AG"},
    { type: 'subTitle', text: 'B'},
    { type: 'textSubT', text: "Baloise Life (Liechtenstein) AG"},
    { type: 'textSubT', text: "Barmenia Allgemeine Versicherungs-AG"},
    { type: 'textSubT', text: "Barmenia Krankenversicherung a.G."},
    { type: 'textSubT', text: "Barmenia Lebensversicherung a.G."},
    { type: 'textSubT', text: "Bayern-Versicherung Lebensversicherung AG"},
    { type: 'textTitl', text: "BHW Bausparkasse AG"},
    { type: 'subTitle', text: 'C'},
    { type: 'textSubT', text: "Canada Life Assurance Europe Ltd."},
    { type: 'textSubT', text: "cash.life AG"},
    { type: 'textSubT', text: "Central Krankenversicherung AG"},
    { type: 'textSubT', text: "comdirect bank AG"},
    { type: 'textSubT', text: "COMMERZBANK AG"},
    { type: 'textSubT', text: "Concordia Krankenversicherungs-AG"},
    { type: 'textSubT', text: "Concordia Lebensversicherungs-AG"},
    { type: 'textSubT', text: "Concordia Rechtsschutz-Versicherungs-AG"},
    { type: 'textSubT', text: "Concordia Versicherungs-Gesellschaft a.G."},
    { type: 'textSubT', text: "Condor Allgemeine Versicherungs-AG"},
    { type: 'textSubT', text: "Condor Lebensversicherungs AG"},
    { type: 'textSubT', text: "Continentale Krankenversicherung a.G."},
    { type: 'textSubT', text: "Continentale Lebensversicherung AG"},
    { type: 'textSubT', text: "Continentale Sachversicherung AG"},
    { type: 'textSubT', text: "Cortal Consors"},
    { type: 'textTitl', text: "CSS Versicherung AG"},
    { type: 'subTitle', text: 'D'},
    { type: 'textSubT', text: "DAB Bank AG"},
    { type: 'textSubT', text: "DBV Winterthur Lebensversicherung AG"},
    { type: 'textSubT', text: "Deutsche Bank AG"},
    { type: 'textSubT', text: "Deutsche Kreditbank"},
    { type: 'textSubT', text: "Deutscher Ring Bausparkasse AG"},
    { type: 'textSubT', text: "Deutscher Ring Krankenversicherung a.G."},
    { type: 'textSubT', text: "Deutscher Ring Lebensversicherungs AG"},
    { type: 'textSubT', text: "Deutscher Ring Sachversicherungs-AG"},
    { type: 'textSubT', text: "DKV Deutsche Krankenversicherung AG"},
    { type: 'textSubT', text: "DLVAG"},
    { type: 'textSubT', text: "DSL Bank AG"},
    { type: 'textTitl', text: "DWS Investment GmbH"},
    { type: 'subTitle', text: 'E'},
    { type: 'textSubT', text: "ebase GmbH"},
    { type: 'textSubT', text: "EUROPA Lebensversicherung AG"},
    { type: 'textTitl', text: "EUROPA Versicherung AG"},
    { type: 'subTitle', text: 'F'},
    { type: 'textSubT', text: "Fondsdepot Bank GmbH"},
    { type: 'textSubT', text: "Fondskonzept AG"},
    { type: 'textSubT', text: "FondsServiceBank"},
    { type: 'textTitl', text: "Frankfurter Fondsbank GmbH"},
    { type: 'subTitle', text: 'G'},
    { type: 'textSubT', text: "Gothaer Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "Gothaer Krankenversicherung AG"},
    { type: 'textTitl', text: "Gothaer Lebensversicherung AG"},
    { type: 'subTitle', text: 'H'},
    { type: 'textSubT', text: "Haftpflichtkasse Darmstadt"},
    { type: 'textSubT', text: "Hallesche Krankenversicherung a.G."},
    { type: 'textSubT', text: "HanseMerkur Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "HanseMerkur Krankenversicherung a.G."},
    { type: 'textSubT', text: "HanseMerkur Lebensversicherung AG"},
    { type: 'textSubT', text: "HanseMerkur Reiseversicherung AG"},
    { type: 'textTitl', text: "HDI-Gerling Firmen und Privat Versicherung AG"},
    { type: 'subTitle', text: 'I'},
    { type: 'textSubT', text: "ING-Diba AG"},
    { type: 'textSubT', text: "INTER Krankenversicherung aG"},
    { type: 'textSubT', text: "INTER Lebensversicherung AG"},
    { type: 'textTitl', text: "Interrisk Versicherung AG"},
    { type: 'subTitle', text: 'J'},
    { type: 'textTitl', text: "Janitos Versicherung AG"},
    { type: 'subTitle', text: 'K'},
    { type: 'textTitl', text: "Karlsruher Lebensversicherung AG"},
    { type: 'subTitle', text: 'L'},
    { type: 'textSubT', text: "Lebensversicherung von 1871 a.G. M"},
    { type: 'textTitl', text: "LVM Versicherung"},
    { type: 'subTitle', text: 'M'},
    { type: 'textSubT', text: "Moventum"},
    { type: 'textTitl', text: "Münchener Verein Krankenversicherung"},
    { type: 'subTitle', text: 'N'},
    { type: 'textSubT', text: "Nürnberger Lebensversicherung AG"},
    { type: 'textTitl', text: "Nürnberger Versicherung AG"},
    { type: 'subTitle', text: 'O'},
    { type: 'textTitl', text: "One Consulting GmbH"},
    { type: 'subTitle', text: 'P'},
    { type: 'textSubT', text: "Project Vermittlungs GmbH"},
    { type: 'textTitl', text: "PSD Berlin Brandenburg"},
    { type: 'subTitle', text: 'R'},
    { type: 'textTitl', text: "ROLAND Rechtsschutz-Versicherungs-AG"},
    { type: 'subTitle', text: 'S'},
    { type: 'textSubT', text: "SIGNAL IDUNA Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "SIGNAL IDUNA Bauspar AG"},
    { type: 'textSubT', text: "SIGNAL IDUNA Pensionskasse AG"},
    { type: 'textSubT', text: "SIGNAL Krankenversicherung a.G."},
    { type: 'textSubT', text: "Skandia Lebensversicherung AG"},
    { type: 'textSubT', text: "Sparda Bank Berlin eG"},
    { type: 'textSubT', text: "Stuttgarter Lebensversicherung a.G."},
    { type: 'textSubT', text: "Stuttgarter Versicherung AG"},
    { type: 'textTitl', text: "Swiss Life AG"},
    { type: 'subTitle', text: 'U'},
    { type: 'textSubT', text: "uniVersa Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "uniVersa Krankenversicherung a.G."},
    { type: 'textTitl', text: "uniVersa Lebensversicherung a.G."},
    { type: 'subTitle', text: 'V'},
    { type: 'textSubT', text: "Volkswohl-Bund Lebensversicherung a.G."},
    { type: 'textTitl', text: "VPV Lebensversicherungs-AG"},
    { type: 'subTitle', text: 'W'},
    { type: 'textSubT', text: "WWK Allgemeine Versicherung AG"},
    { type: 'textTitl', text: "WWK Lebensversicherung a.G."},
    { type: 'subTitle', text: 'Z'},
    { type: 'textSubT', text: "Zurich Deutscher Herold Lebensversicherung AG"},
    { type: 'textTitl', text: "Zurich Versicherung AG"},
    { type: 'textTitl', text: "http://www.myfeelix.de/feelix-kooperationspartner/"},
];

export default terms;
