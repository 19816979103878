/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a popup for all
 */

import React from 'react';
import Button from '../buttons/Button';
import trans from '../../trans';

export default class Popup extends React.Component {
    /*
     * constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            isShow: !!this.props.isShow,
            params: {},
        };
    }

    /*
     * right button click handler
     */
    rightClick() {
        this.close();

        if (this.props.rightFunc) {
            this.props.rightFunc({ ...this.state.params });
        }
    }

    /*
     * right button click handler
     */
    leftClick() {
        this.close();

        if (this.props.leftFunc) {
            this.props.leftFunc({ ...this.state.params });
        }
    }

    /*
     * public function that is used to show popup
     */
    show(params) {
        this.setState({ isShow: true, params: !params ? {} : params });
    }

    /*
     * public function that is used to hide popup
     */
    close() {
        this.setState({ isShow: false });
    }

    /*
     * rendering
     */
    render() {
        if (!this.state.isShow) {
            return null;
        }
        let className = 'popup popup-common';
        if (this.props.isAutoHeight) {
            className += ' popup-auto-height';
        }
        const role = this.props.role || 'popup';
        return (
            <div className={className} role={role}>
                <div className="container">
                    {!!this.props.title && (
                        <div className="container-top">
                            <div className="title">{this.props.title}</div>
                        </div>
                    )}
                    <div className="container-inner">
                        <div className={this.props.noScroll ? 'no-scroll' : 'scroll'}>{this.props.children}</div>
                    </div>
                    <div className="container-bottom">
                        {!!this.props.leftBtnTitle && <Button text={this.props.leftBtnTitle} onClick={() => this.leftClick()} className="left-btn" isWhite role={role + 'BtnLeft'} />}
                        <Button text={this.props.rightBtnTitle || trans.t('common', 'ok')} onClick={() => this.rightClick()} className="right-btn" role={role + 'BtnRight'} />
                    </div>
                </div>
            </div>
        );
    }
}
