const agreement = [
    { type: 'secTitle', text: '03 Erstinformation'},
    { type: 'textTitl', text: 'Der vorliegende Maklervertrag gilt für alle Verträge des Kunden die der Kunde in der feelix App verwaltet und für die er den feelix Maklerservice aktiviert hat.'},
    { type: 'catTitle', text: 'Risikoänderungen'},
    { type: 'textTitl', text: 'Der Kunde ist verpflichtet, für ihn erkennbar vertrags- und risikorelevante Änderungen, die den Versicherungsschutz betreffen (z.B. Umzug, Familiengründung, Anschaffungen, Betriebsverlegung etc.), dem Makler unverzüglich mitzuteilen.'},
    { type: 'catTitle', text: 'Kündigung'},
    { type: 'textTitl', text: 'Der Maklervertrag ist auf unbestimmte Zeit geschlossen und kann vom Kunden jederzeit ohne Einhaltung einer Frist gekündigt werden. Der Makler kann den Vertrag mit einer Frist von einem Monat kündigen. Die Kündigung bedarf der Textform (z.B. per E-Mail).'},
    { type: 'textTitl', text: 'Wechsel des Vertragspartners und Weitergabe Ihrer Kundendaten an diesen Sollte der Makler seinen Geschäftsbetrieb ganz oder teilweise auf einen anderen Makler übertragen (z. B. im Rahmen der Veräußerung des Geschäftsbetriebes), wird der Makler den Maklerwechsel anzeigen. Der Kunde ist berechtigt, innerhalb eines Monats nach Anzeige der Übertragung von Daten an den neuen Makler zu widersprechen.'},
    { type: 'catTitle', text: 'Haftungsbegrenzung'},
    { type: 'textTitl', text: 'Der Makler ist von den Beschränkungen des § 181 BGB befreit.'},
    { type: 'catTitle', text: 'Maklervergütung'},
    { type: 'textTitl', text: 'Die Courtage für die vom Makler vermittelten und verwalteten Verträge ist Bestandteil der Versicherungsprämie und wird dem Makler vom Versicherer vergütet. Durch die Tätigkeit des Maklers entstehen dem Kunden keine zusätzlichen Kosten. '},
    { type: 'catTitle', text: 'Aufhebung früherer Verträge'},
    { type: 'textTitl', text: 'Mit Abschluss dieses Vertrages verlieren alle eventuell früher zwischen den Parteien geschlossenen Maklerverträge ihre Gültigkeit.'},
    { type: 'catTitle', text: 'Datenverarbeitung'},
    { type: 'textTitl', text: 'Wir erheben, verarbeiten und nutzen Ihre personenbezogenen Daten in Übereinstimmung mit dem Inhalt unserer Datenschutzerklärung sowie den anwendbaren  Datenschutzgesetzen, insbesondere der Datenschutz-Grundverordnung (DSGVO).'},
    { type: 'textTitl', text: 'Der Kunde willigt ein, dass die vom Makler oder seinen Untervermittlern/Untermaklern oder sonstigen Dritten, deren sich der Makler zur Erbringung der vertragsgegenständlichen Leistungen bedient, angesprochenen Versicherer im erforderlichen Umfang Daten, die sich aus den Antragsunterlagen oder der Vertragsdurchführung (Beiträge, Versicherungsfälle, Risiko-/ Vertragsänderungen) ergeben, an Rückversicherer zur Beurteilung des Risikos und der Ansprüche an andere Versicherer und an ihren Verband übermitteln. Diese Einwilligung gilt unabhängig vom Zustandekommen des Vertrages auch für entsprechende Prüfungen bei anderweitig beantragten (Versicherungs-)Verträgen und bei künftigen Anträgen.'},
    { type: 'textTitl', text: 'Der Kunde wünscht die Betreuung dieser Verträge durch den Makler und deren Dienstleister:'},
    { type: 'textSubT', text: '● FondsKonzept AG, Ulmer Str. 6, 89257 Illertissen'},
    { type: 'textTitl', text: '● blau direkt GmbH & Co.KG, Kaninchenborn 31, 23560 Lübeck'},
    { type: 'textTitl', text: 'Der Kunde willigt ferner ein, dass diese Versicherer, soweit dies zur ordnungsgemäßen Durchführung seiner Versicherungsangelegenheiten erforderlich ist, allgemeine Vertrags-, Abrechnungs- und Leistungsdaten in gemeinsamen Datensammlungen führen und an den Makler oder dessen Untervermittler/Untermakler, deren sich der Makler zur Erbringung der vertragsgegenständlichen Leistungen bedient, weitergeben.'},
    { type: 'textTitl', text: 'Gesundheitsdaten dürfen nur an Personen- und Rückversicherer übermittelt werden; an den Makler oder dessen Untervermittler/Untermakler oder sonstige Dritte, deren sich der Makler zur Erbringung der vertragsgegenständlichen Leistungen bedient, dürfen sie nur weitergegeben werden, soweit dies zur Vertragsgestaltung erforderlich ist. '},
    { type: 'textTitl', text: 'Diese Einwilligung gilt nur, wenn der Kunde die Möglichkeit hatte, in zumutbarer Weise vom Inhalt des vom Versicherer bereitgehaltenen Merkblattes zur Datenverarbeitung Kenntnis zu nehmen.'},
    { type: 'textTitl', text: 'Etwaige Benachrichtigungen nach Art. 14 DSGVO  sind über den Makler an den Kunden zu richten.'},
    { type: 'catTitle', text: 'Datenschutzbeauftragter'},
    { type: 'textSubT', text: 'Verantwortlicher für die Datenverarbeitung ist'},
    { type: 'textTitl', text: 'feelix GmbH, Innsbrucker Str. 38 10825 Berlin vertreten durch Geschäftsführer Tilo Hammer Tel +49 30 567 95 492, Fax  +49 30 577 00 5731, helpdesk@myfeelix.de'},
    { type: 'textSubT', text: 'Kontaktdaten unseres Datenschutzbeauftragten:'},
    { type: 'textTitl', text: 'Tilo Hammer, datenschutz@myfeelix.de, Telefon +49 30 567 95 492, Fax +49 30 577 00 5731'},
    { type: 'catTitle', text: 'Salvatorische Klausel'},
    { type: 'textTitl', text: 'Sollte eine Bestimmung dieses Vertrages unwirksam sein oder werden, so bleiben die übrigen Bestimmungen dieses Vertrages hiervon unberührt, es sei denn, dass durch den Wegfall einzelner Klauseln eine Vertragspartei so unzumutbar benachteiligt würde, dass ihr ein Festhalten am Vertrag nicht mehr zugemutet werden kann.'},
    { type: 'catTitle', text: 'Widerrufsbelehrung'},
    { type: 'textTitl', text: 'Sie können Ihre Vertragserklärung innerhalb von 14 Tagen ohne Angabe von Gründen mittels einer eindeutigen Erklärung widerrufen. Die Frist beginnt nach Erhalt dieser Belehrung auf einem dauerhaften Datenträger, jedoch nicht vor Vertragsschluss und auch nicht vor Erfüllung unserer Informationspflichten gemäß Artikel 246b § 2 Absatz 1 in Verbindung mit Artikel 246b § 1 Absatz 1 EGBGB.'},
    { type: 'textTitl', text: 'Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs, wenn die Erklärung auf einem dauerhaften Datenträger (z. B. Brief, Tele-fax, E-Mail) erfolgt. Der Widerruf ist zu richten an:'},
    { type: 'textSubT', text: 'feelix GmbH'},
    { type: 'textSubT', text: 'Innsbrucker Str. 38 10825 Berlin'},
    { type: 'textSubT', text: 'Telefon: +49 30 567 95 492'},
    { type: 'textSubT', text: 'Telefax: +49 30 577 00 5731'},
    { type: 'textTitl', text: 'E-Mail: helpdesk@myfeelix.de'},
    { type: 'catTitle', text: 'Widerrufsfolgen'},
    { type: 'textTitl', text: 'Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen zurückzugewähren. Sie sind zur Zahlung von Wertersatz für die bis zum Widerruf erbrachte Dienstleistung verpflichtet, wenn Sie vor Abgabe Ihrer Vertragserklärung auf diese Rechtsfolge hingewiesen wurden und ausdrücklich zugestimmt haben, dass wir vor dem Ende der Widerrufsfrist mit der Ausführung der Gegenleistung beginnen.'},
    { type: 'textTitl', text: 'Besteht eine Verpflichtung zur Zahlung von Wertersatz, kann dies dazu führen, dass Sie die vertraglichen Zahlungsverpflichtungen für den Zeitraum bis zum Widerruf dennoch erfüllen müssen. Ihr Widerrufsrecht erlischt vorzeitig, wenn der Vertrag von beiden Seiten auf Ihren ausdrücklichen Wunsch vollständig erfüllt ist, bevor Sie Ihr Widerrufsrecht ausgeübt haben. Verpflichtungen zur Erstattung von Zahlungen müssen innerhalb von 30 Tagen erfüllt werden. Die Frist beginnt für Sie mit der Absendung Ihrer Widerrufserklärung, für uns mit deren Empfang.'},
    { type: 'textTitl', text: 'Ende der Widerrufsbelehrung'},
];

export default agreement;
