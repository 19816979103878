/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: secureContractsList
 * path: /contracts
 *
 * this is a page that shows added contracts
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import HeaderSecure from '../../../components/theme/HeaderSecure';
import SearchWidget from '../../../components/elements/SearchWidget';
import ProviderImage from '../../../components/elements/ProviderImage';
import Popup from '../../../components/popups/Popup';
import Alert from '../../../components/popups/Alert';
import Loading from '../../../components/elements/Loading';
import ButtonDashboard from '../../../components/buttons/ButtonDashboard';
import pageUrl from '../../../const/pages';
import config from '../../../config';
import trans from '../../../trans';
import Utils from '../../../lib/Utils';
import '../../../styles/secure.scss';
import IconTodo from '../../../assets/icon-todo.svg';
import IconCoins from '../../../assets/icon-coins.svg';
import IconQR from '../../../assets/icon-qr.svg';
import IconMoney from '../../../assets/icon-money-44.png';
import IconClock from '../../../assets/icon-clock-44.png';

const ContractsListPage = observer(({ DataStore, ContractsStore, UserStore }) => {
    //get current customer
    const { customer } = UserStore;

    //state variables
    const defContract = ContractsStore.getFilteredContracts();
    const [isLoading, setIsLoading] = useState(false);
    const [contracts, setContracts] = useState(defContract);

    //ref variables
    const refPopup = useRef();
    const refPopupCashbackRed = useRef();
    const refPopupCashbackYellow = useRef();
    const refAlert = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //filter contracts handler that allows to set filtered contracts array
    const filterContracts = (searchText, statusContract, order) => {
        setContracts(ContractsStore.getFilteredContracts(searchText, statusContract, order));
    };

    //show cashback popup
    const showBrokerPopup = async (contractBrokerStatusValue, contractId) => {
        switch (contractBrokerStatusValue) {
            case 'active_without_fee':
            case 'reject':
                refPopup.current.show();
                break;
            case 'open':
            case 'deactivation_broker':
                await activateBrokerContract(contractId);
                break;
            default:
                break;
        }
        return true;
    };

    //activate contract cashback
    const activateBrokerContract = async (contractId) => {
        setIsLoading(true);
        const res = await ContractsStore.activateBrokerForContracts([contractId]);
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        }
        const newContracts = ContractsStore.getFilteredContracts();
        setContracts(newContracts);
        return true;
    };

    //render contracts function
    const renderCashbackInfo = (cashbackStatus, cashbackValue, contractId) => {
        switch (cashbackStatus) {
            case 'red':
                return (
                    <div className={'cashback-info cashback-' + cashbackStatus}>
                        <div className="link" onClick={() => refPopupCashbackRed.current.show({ contractId })} role={'btnCashbackPopup' + contractId}>
                            {trans.t('secureContractsListPage', 'cashbackRedLink')}
                        </div>
                        <div className="line">|</div>
                        <div className="text">{trans.t('secureContractsListPage', 'cashbackRedText')}</div>
                        <div className="value">{Utils.formatPrice(cashbackValue)}</div>
                    </div>
                );
            case 'yellow':
                return (
                    <div className={'cashback-info cashback-' + cashbackStatus}>
                        <div className="circle" />
                        <div className="link" onClick={() => refPopupCashbackYellow.current.show()} role={'btnCashbackPopup' + contractId}>
                            {trans.t('secureContractsListPage', 'cashbackYellowLink')}
                        </div>
                    </div>
                );
            case 'cashback':
                return (
                    <div className={'cashback-info cashback-' + cashbackStatus}>
                        <div className="money">
                            <img src={IconMoney} alt="" />
                        </div>
                        <div className="text">{trans.t('secureContractsListPage', 'cashbackMoneyText')}</div>
                        <div className="value">{Utils.formatPrice(cashbackValue)}</div>
                    </div>
                );
            case 'clock':
                return (
                    <div className={'cashback-info cashback-' + cashbackStatus}>
                        <div className="clock">
                            <img src={IconClock} alt="" />
                        </div>
                        <div className="text">{trans.t('secureContractsListPage', 'cashbackClockText1')}</div>
                        <div className="line">|</div>
                        <div className="text">{trans.t('secureContractsListPage', 'cashbackClockText2')}</div>
                        <div className="value">{Utils.formatPrice(cashbackValue)}</div>
                    </div>
                );
            case 'grey':
            default:
                return <div className={'cashback-info cashback-' + cashbackStatus} />;
        }
    };

    //render contracts function
    const renderContract = (contract, index) => {
        const { productTitle, providerTitle, providerLogoUrl, brokerStatusValue, numberValue, yearlyCostValue, statusTitle, numberDocuments, tariffsArr, cashbackStatus, cashbackValue } =
            contract;

        return (
            <div className="item" key={index}>
                <div className={'cashback-color cashback-' + cashbackStatus} />
                <div className="item-inner">
                    <div className="item-title" onClick={() => navigate(pageUrl.secureContractDetail, { state: { contractId: contract.idValue } })}>
                        <div className="title">{providerTitle}</div>
                        <div className="title-product">{productTitle}</div>
                    </div>
                    <div className="detail">
                        <div className="detail-item provider">
                            <ProviderImage title={providerTitle} imageSrc={providerLogoUrl} />
                        </div>
                        <div className="detail-item">
                            <div className="title">{trans.t('secureContractsListPage', 'contractNumber')}.:</div>
                            <div>{numberValue || '-'}</div>
                        </div>
                        <div className="detail-item">
                            <div className="title">{trans.t('secureContractsListPage', 'contractYearlyCost')}:</div>
                            <div>{Utils.formatPrice(yearlyCostValue)}</div>
                        </div>
                        <div className="detail-item">
                            <div className="title">{trans.t('secureContractsListPage', 'contractStatus')}:</div>
                            <div>{statusTitle || '-'}</div>
                        </div>
                        <div className="detail-item">
                            <div className="title">{trans.t('secureContractsListPage', 'contractDocuments')}:</div>
                            <div className="documents-item">
                                <div>{numberDocuments}</div>
                                <div className="add-documents" onClick={() => navigate(pageUrl.secureContractDocuments, { state: { contractId: contract.idValue } })} />
                            </div>
                        </div>
                        {!!tariffsArr.length && (
                            <div className="detail-item">
                                <div className="title">{trans.t('secureContractsListPage', 'contractTariffs')}:</div>
                                {tariffsArr.map((tariff, index) => (
                                    <div key={index}>{tariff}</div>
                                ))}
                            </div>
                        )}
                    </div>
                    {!config.features.cashback && (
                        <div
                            role={'btnCashbackPopup' + contract.idValue}
                            onClick={() => showBrokerPopup(brokerStatusValue, contract.idValue)}
                            className={'broker-status ' + brokerStatusValue}
                        >
                            <span />
                            {trans.t('secureContractsListPage', 'brokerStatus_' + brokerStatusValue)}
                        </div>
                    )}
                    {config.features.cashback && renderCashbackInfo(cashbackStatus, cashbackValue, contract.idValue)}
                </div>
            </div>
        );
    };

    const { totalCashbackValue } = ContractsStore;

    return (
        <div className="app secure-contract-list-page">
            <HeaderSecure isTopMenu isSlideMenu selected={pageUrl.secureContractsList}>
                <div className="wrapper-big wrapper-dashboard">
                    <h3>{trans.tParams('secureDashboardPage', 'titleHeader', { name: customer.firstNameValue })}</h3>
                    <div className="buttons-container">
                        <div className="buttons-container-left">
                            <ButtonDashboard icon={IconTodo} titleNumber={ContractsStore.getNumberOfContracts + ' '} titleText={trans.t('secureDashboardPage', 'contracts')} />
                            <ButtonDashboard icon={IconCoins} titleNumber={Utils.formatPrice(ContractsStore.getPayments)} titleText={trans.t('secureDashboardPage', 'payments')} />
                        </div>
                        <div className="buttons-container-right">
                            <ButtonDashboard icon={IconMoney} titleNumber={Utils.formatPrice(totalCashbackValue)} titleText={trans.t('secureContractsListPage', 'cashbackTotalValue')} />
                            <ButtonDashboard icon={IconQR} titleText={trans.t('secureDashboardPage', 'qrText')} onClick={() => navigate(pageUrl.secureReferral)} isWhite noRightMargin />
                        </div>
                    </div>
                </div>
            </HeaderSecure>
            <div className="content">
                <div className="wrapper-big">
                    <div className="contract-filter">
                        <div className="contract-filter-title">
                            <h1>{trans.t('secureContractsListPage', 'h1')}</h1>
                            <div className="contract-filter-add" onClick={() => navigate(UserStore.isCompany ? pageUrl.secureNewContractCompany : pageUrl.secureNewContract)} />
                        </div>
                        <div className="contract-filter-inner">
                            <SearchWidget onChange={filterContracts} filterList={DataStore.contractStatusList} />
                        </div>
                    </div>
                </div>
                <div className="wrapper-big wrapper-big-without-padding">
                    <div className="contracts">{contracts.map((contract, index) => renderContract(contract, index))}</div>
                </div>
            </div>
            <Popup
                ref={refPopup}
                isAutoHeight
                title={trans.t('secureContractsListPage', 'contractPopupTitle')}
                leftBtnTitle={trans.t('secureContractsListPage', 'contractPopupLeftBtnTitle')}
                rightBtnTitle={trans.t('secureContractsListPage', 'contractPopupRightBtnTitle')}
                rightFunc={() => window.open(config.bitrix24Urls.contractPopupUrl, '_blank').focus()}
            >
                <div className="contract-popup-text">
                    {trans.t('secureContractsListPage', 'contractPopupText1')}
                    <br />
                    <br />
                    {trans.t('secureContractsListPage', 'contractPopupText2')}
                </div>
            </Popup>
            <Popup
                ref={refPopupCashbackRed}
                role="refPopupCashbackRed"
                isAutoHeight
                noScroll
                title={trans.t('secureContractsListPage', 'cashbackRedPopupTitle')}
                leftBtnTitle={trans.t('secureContractsListPage', 'cashbackRedPopupLeftBtnTitle')}
                rightBtnTitle={trans.t('secureContractsListPage', 'cashbackRedPopupRightBtnTitle')}
                rightFunc={({ contractId }) => activateBrokerContract(contractId)}
            >
                <div className="contract-popup-text">{trans.t('secureContractsListPage', 'cashbackRedPopupText')}</div>
            </Popup>
            <Popup
                ref={refPopupCashbackYellow}
                role="refPopupCashbackYellow"
                isAutoHeight
                noScroll
                title={trans.t('secureContractsListPage', 'cashbackYellowPopupTitle')}
                leftBtnTitle={trans.t('secureContractsListPage', 'cashbackYellowPopupLeftBtnTitle')}
                rightBtnTitle={trans.t('secureContractsListPage', 'cashbackYellowPopupRightBtnTitle')}
                rightFunc={() => window.open(config.bitrix24Urls.contractPopupUrl, '_blank').focus()}
            >
                <div className="contract-popup-text">
                    {trans.t('secureContractsListPage', 'cashbackYellowPopupText1')}
                    <br />
                    <br />
                    <span className="bold">{trans.t('secureContractsListPage', 'cashbackYellowPopupTipp')}</span>
                    {trans.t('secureContractsListPage', 'cashbackYellowPopupText2')}
                </div>
            </Popup>
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default ContractsListPage;
