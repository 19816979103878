/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that show register button
 */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import pageUrl from '../../const/pages';
import trans from '../../trans';

export default function ButtonRegister() {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    return (
        <div className="btn-auth-register">
            <Button text={trans.t('common', 'btnRegister')} onClick={() => navigate(pageUrl.authStartRegister)} isTransparentWhite />
        </div>
    );
}
