/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a header
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import trans from '../../trans';
import IconLogo from '../../assets/icon-logo.png';
import IconLogoMobile from '../../assets/icon-logo-mobile-white.png';
import IconLogoBigMobile from '../../assets/icon-logo-big-white.png';
import IconExpert from '../../assets/icon-expert.svg';

export default function Header({ expertTitle, expertMsg, isBack, children, paddingBottom, expertClass, experth1, expertMarginTop, isHideDisabled, isBigLogo }) {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //state variables
    const [isShowExpert, setIsShowExpert] = useState(true);

    const styles = {};

    if (!isMobile && (paddingBottom || paddingBottom === 0)) {
        styles.paddingBottom = paddingBottom + 'px';
    }
    let className = 'header';
    if (isMobile) {
        className += ' header-mobile';
    }

    const mobileLogo = isBigLogo ? IconLogoBigMobile : IconLogoMobile;

    return (
        <div className={className} style={styles}>
            <div className="wrapper-big">
                <div className={isBack ? 'top-with-back' : 'top'}>
                    {!!isBack && <div className="back" onClick={() => navigate(-1)} />}
                    <img src={isMobile ? mobileLogo : IconLogo} alt="Logo" className={isMobile ? 'logo-mobile' : 'logo'} />
                </div>
                {!!expertMsg && (
                    <div className={expertMarginTop ? 'margin-top' : ''}>
                        {isShowExpert && (
                            <div className={'expert ' + (expertClass || '')}>
                                {!!experth1 && <div className="h1">{experth1}</div>}
                                <div className="wrapper">
                                    <img src={IconExpert} alt="Experte" />
                                    <div className="texts">
                                        {expertTitle && <div className="titleExpert">{expertTitle}</div>}
                                        {!expertTitle && <div className="title">{trans.t('common', 'expertTitle')}</div>}
                                        <div className="text">{expertMsg}</div>
                                    </div>
                                </div>
                                {isMobile && !!children && <div className="wrapper">{children}</div>}
                                {!isHideDisabled && isMobile && <div className="close" onClick={() => setIsShowExpert(false)} />}
                            </div>
                        )}
                        {!isShowExpert && (
                            <div className={'expert expert-closed ' + (expertClass || '')}>
                                <div className="wrapper">
                                    <img src={IconExpert} alt="Experte" />
                                    <div className="texts">
                                        {expertTitle && <div className="titleExpert">{expertTitle}</div>}
                                        {!expertTitle && <div className="title">{trans.t('common', 'expertTitle')}</div>}
                                    </div>
                                </div>
                                <div className="open" onClick={() => setIsShowExpert(true)} />
                            </div>
                        )}
                    </div>
                )}
                {!isMobile && !!children && <div className="wrapper">{children}</div>}
            </div>
        </div>
    );
}
