export default {
    brokerCommission: {
        foreign_health_insurance: {
            value: 1,
            type: 'percent',
        },
        property_construction: {
            value: 12,
            type: 'percent',
        },
        construction_legal_insurance: {
            value: 12,
            type: 'percent',
        },
        construction_insurance: {
            value: 12,
            type: 'percent',
        },
        building_saving_return: {
            value: 1,
            type: 'percent',
        },
        building_saving: {
            value: 1,
            type: 'percent',
        },
        allowance: {
            value: 1,
            type: 'percent',
        },
        professional_liability_insurance: {
            value: 20,
            type: 'percent',
        },
        disability: {
            value: 1.5,
            type: 'percent',
        },
        company_pension: {
            value: 1.5,
            type: 'percent',
        },
        service_liability_insurance: {
            value: 8,
            type: 'percent',
        },
        invalidity_insurance: {
            value: 1.5,
            type: 'percent',
        },
        drone_insurance: {
            value: 8,
            type: 'percent',
        },
        unit_linked_life_insurance: {
            value: 1.5,
            type: 'percent',
        },
        investment_pension_insurance: {
            value: 1.5,
            type: 'percent',
        },
        building_legal_insurance: {
            value: 20,
            type: 'percent',
        },
        business_car_insurance: {
            value: 8,
            type: 'percent',
        },
        business_law_insurance: {
            value: 20,
            type: 'percent',
        },
        business_building_insurance: {
            value: 20,
            type: 'percent',
        },
        base_holder: {
            value: 20,
            type: 'percent',
        },
        basic_skill_insurance: {
            value: 1.5,
            type: 'percent',
        },
        bicycle_insurance_commercial: {
            value: 20,
            type: 'percent',
        },
        home_content_insurance: {
            value: 20,
            type: 'percent',
        },
        dog_operation_insurance: {
            value: 5,
            type: 'percent',
        },
        liability_insurance_for_dogs: {
            value: 20,
            type: 'percent',
        },
        dog_health_insurance: {
            value: 10,
            type: 'percent',
        },
        endowment_policy: {
            value: 1.5,
            type: 'percent',
        },
        cat_operation_insurance: {
            value: 5,
            type: 'percent',
        },
        cat_health_insurance: {
            value: 10,
            type: 'percent',
        },
        car_partly_insurance: {
            value: 8,
            type: 'percent',
        },
        car_liability_insurance: {
            value: 8,
            type: 'percent',
        },
        car_fully_insurance: {
            value: 8,
            type: 'percent',
        },
        childrens_disability_insurance: {
            value: 20,
            type: 'percent',
        },
        hospital_daily_benefits: {
            value: 1,
            type: 'percent',
        },
        sickness_daily_allowance: {
            value: 1,
            type: 'percent',
        },
        supplementary_health_insurance: {
            value: 1,
            type: 'percent',
        },
        horse_operation_insurance: {
            value: 5,
            type: 'percent',
        },
        horse_insurance: {
            value: 20,
            type: 'percent',
        },
        horse_health_insurance: {
            value: 10,
            type: 'percent',
        },
        care_pension: {
            value: 1.5,
            type: 'percent',
        },
        care_insurance: {
            value: 1.5,
            type: 'percent',
        },
        photovoltaic_insurance: {
            value: 15,
            type: 'percent',
        },
        private_liability: {
            value: 20,
            type: 'percent',
        },
        private_health_insurance: {
            value: 1,
            type: 'percent',
        },
        private_pension_insurance: {
            value: 1.5,
            type: 'percent',
        },
        legal_expense_insurance: {
            value: 20,
            type: 'percent',
        },
        riester_pension: {
            value: 1.5,
            type: 'percent',
        },
        base_pension: {
            value: 1.5,
            type: 'percent',
        },
        dread_disease: {
            value: 1.5,
            type: 'percent',
        },
        death_benefit_insurance: {
            value: 1,
            type: 'percent',
        },
        accident_insurance: {
            value: 20,
            type: 'percent',
        },
        homeowners_insurance: {
            value: 20,
            type: 'percent',
        },
        denture: {
            value: 1,
            type: 'percent',
        },
        riester_pension_building_saver: {
            value: 1,
            type: 'percent',
        },
    },
    brokerCashbackCommission: {
        basic: 40,
        plus: 45,
    },
    salesCommission: {
        foreign_health_insurance: {
            value: 8,
            type: 'static',
        },
        car_credit: {
            value: 2,
            type: 'percent',
        },
        property_construction: {
            value: 12,
            type: 'percent',
        },
        construction_legal_insurance: {
            value: 12,
            type: 'percent',
        },
        construction_insurance: {
            value: 12,
            type: 'percent',
        },
        building_saving_return: {
            value: 1,
            type: 'percent',
        },
        building_saving: {
            value: 1,
            type: 'percent',
        },
        allowance: {
            value: 8,
            type: 'static',
        },
        professional_liability_insurance: {
            value: 20,
            type: 'percent',
        },
        disability: {
            value: 4,
            type: 'percent',
        },
        company_pension: {
            value: 4,
            type: 'percent',
        },
        service_liability_insurance: {
            value: 8,
            type: 'percent',
        },
        invalidity_insurance: {
            value: 4,
            type: 'percent',
        },
        drone_insurance: {
            value: 8,
            type: 'percent',
        },
        unit_linked_life_insurance: {
            value: 4,
            type: 'percent',
        },
        investment_pension_insurance: {
            value: 4,
            type: 'percent',
        },
        building_legal_insurance: {
            value: 20,
            type: 'percent',
        },
        business_car_insurance: {
            value: 8,
            type: 'percent',
        },
        business_law_insurance: {
            value: 20,
            type: 'percent',
        },
        business_building_insurance: {
            value: 20,
            type: 'percent',
        },
        base_holder: {
            value: 20,
            type: 'percent',
        },
        basic_skill_insurance: {
            value: 4,
            type: 'percent',
        },
        home_content_insurance: {
            value: 20,
            type: 'percent',
        },
        dog_operation_insurance: {
            value: 5,
            type: 'percent',
        },
        liability_insurance_for_dogs: {
            value: 20,
            type: 'percent',
        },
        dog_health_insurance: {
            value: 10,
            type: 'percent',
        },
        property_loan: {
            value: 1,
            type: 'percent',
        },
        endowment_policy: {
            value: 4,
            type: 'percent',
        },
        cat_operation_insurance: {
            value: 5,
            type: 'percent',
        },
        cat_health_insurance: {
            value: 10,
            type: 'percent',
        },
        car_partly_insurance: {
            value: 8,
            type: 'percent',
        },
        car_liability_insurance: {
            value: 8,
            type: 'percent',
        },
        car_fully_insurance: {
            value: 8,
            type: 'percent',
        },
        childrens_disability_insurance: {
            value: 20,
            type: 'percent',
        },
        hospital_daily_benefits: {
            value: 8,
            type: 'static',
        },
        sickness_daily_allowance: {
            value: 8,
            type: 'static',
        },
        supplementary_health_insurance: {
            value: 8,
            type: 'static',
        },
        horse_operation_insurance: {
            value: 5,
            type: 'percent',
        },
        horse_insurance: {
            value: 20,
            type: 'percent',
        },
        horse_health_insurance: {
            value: 10,
            type: 'percent',
        },
        care_pension: {
            value: 4,
            type: 'percent',
        },
        care_insurance: {
            value: 4,
            type: 'percent',
        },
        photovoltaic_insurance: {
            value: 15,
            type: 'percent',
        },
        private_liability: {
            value: 20,
            type: 'percent',
        },
        private_health_insurance: {
            value: 8,
            type: 'static',
        },
        private_pension_insurance: {
            value: 4,
            type: 'percent',
        },
        private_loan: {
            value: 2,
            type: 'percent',
        },
        legal_expense_insurance: {
            value: 20,
            type: 'percent',
        },
        riester_pension: {
            value: 4,
            type: 'percent',
        },
        base_pension: {
            value: 4,
            type: 'percent',
        },
        dread_disease: {
            value: 4,
            type: 'percent',
        },
        death_benefit_insurance: {
            value: 4,
            type: 'percent',
        },
        accident_insurance: {
            value: 20,
            type: 'percent',
        },
        homeowners_insurance: {
            value: 20,
            type: 'percent',
        },
        denture: {
            value: 8,
            type: 'static',
        },
        riester_pension_building_saver: {
            value: 1,
            type: 'percent',
        },
    },
    salesSplitPayment: {
        allowance: [20, 20, 20, 20, 20],
        disability: [20, 20, 20, 20, 20],
        company_pension: [20, 20, 20, 20, 20],
        invalidity_insurance: [20, 20, 20, 20, 20],
        unit_linked_life_insurance: [20, 20, 20, 20, 20],
        investment_pension_insurance: [20, 20, 20, 20, 20],
        basic_skill_insurance: [20, 20, 20, 20, 20],
        endowment_policy: [20, 20, 20, 20, 20],
        care_pension: [20, 20, 20, 20, 20],
        care_insurance: [20, 20, 20, 20, 20],
        private_health_insurance: [20, 20, 20, 20, 20],
        private_pension_insurance: [20, 20, 20, 20, 20],
        riester_pension: [20, 20, 20, 20, 20],
        base_pension: [20, 20, 20, 20, 20],
        dread_disease: [20, 20, 20, 20, 20],
        death_benefit_insurance: [20, 20, 20, 20, 20],
        riester_pension_building_saver: [20, 20, 20, 20, 20],
    },
    salesCashbackCommission: {
        basic: 20,
        plus: 25,
    },
    valueAddedTax: 19,
    commissionCap: 80,
    maximumYearlyCashback: 15.0,
    memberCommission: {
        silver: 15,
        gold: 9,
        platinum: 6,
        titanium: 5,
    },
};
