/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is API that requests data from finAPI
 */

import JwtAPI from '../JwtAPI';
import config from '../../config';

const API = {
    /*
     * get status object
     */
    getResponseObj: (status, code, data, msg, errorId) => {
        return JwtAPI.getResponseObj(status, code, data, msg, errorId);
    },
    /*
     * get elements
     * @grantType - string -  password | client_credentials | refresh_token
     * @refreshToken - string (optional)
     * @username - string (optional)
     * @password - string (optional)
     */
    getFinAPIToken: async ({ refreshToken, username, password }) => {
        const params = { grant_type: 'client_credentials' };
        if (refreshToken) {
            params.grant_type = 'refresh_token';
            params.refresh_token = refreshToken;
        }
        if (username && password) {
            params.grant_type = 'password';
            params.username = username;
            params.password = password;
        }

        const res = await JwtAPI.request('get', '1', '/finapi/token', null, params);
        return res;
    },

    /*
     * set access_token for api
     */
    setAccessToken: (token) => {
        JwtAPI.setFinAPIToken(token);
    },

    /*
     * create new user - https://docs.finapi.io/#post-/api/v1/users
     *
     * Create a new user. Must pass your global (i.e. client) access_token.
     * This service returns the user's password as plain text.
     * The automatic update of the user's bank connections is disabled by default for any new user. User identifiers are regarded case-insensitive by finAPI.
     */
    createCustomer: async ({ id, password, email, phone }) => {
        const res = await JwtAPI.request('post', '1', '/finapi/access/users', { id, password, email, phone, isAutoUpdateEnabled: true, bearerToken: JwtAPI.getFinAPIToken() });
        return res;
    },

    /*
     * Import a bank connection and get webURL - https://docs.finapi.io/#post-/api/webForms/bankConnectionImport
     *
     * Imports and groups all the bank data the end user has at the specific bank into a bank connection (identifiable with a bankConnectionId).
     * The endpoint connects to all available interfaces(XS2A, finTS and Web Scraper) for the bank. Hence, the end user will be prompted for
     * credentials and SCA for every loop over each of the interface. For best results, consider your use case and review the API parameter,
     * accountTypes list before you begin your integration.
     */
    bankConnectionImport: async () => {
        const res = await JwtAPI.request('post', '1', '/finapi/webForms/bankConnectionImport', { profileId: config.finAPI.profileId, bearerToken: JwtAPI.getFinAPIToken() });
        return res;
    },

    /*
     * get webform - https://docs.finapi.io/#get-/api/webForms/-id-
     */
    getWebForm: async (formId) => {
        const res = await JwtAPI.request('post', '1', '/finapi/webForms/' + formId, null, { bearerToken: JwtAPI.getFinAPIToken() });
        return res;
    },

    /*
     * get bank connection - https://docs.finapi.io/#get-/api/v1/bankConnections/-id-
     *
     * Get a single bank connection of the user that is authorized by the access_token. Must pass the connection's identifier and the user's access_token.
     * Web Form 2.0 customers should also use this endpoint to learn about the status of the bank connection (The bank connection ID can be found in the
     * payload of the API response from the relevant Web Form 2.0 API endpoint).
     */
    getBankConnection: async (id) => {
        const res = await JwtAPI.request('get', '1', '/finapi/access/bankConnections/' + id, null, { bearerToken: JwtAPI.getFinAPIToken() });
        return res;
    },

    /*
     * Synchronize bank connection data sources with backends - https://docs.finapi.io/#post-/dataSources/bankConnections/synchronization
     *
     * The service synchronizes bank connections with the data sources. It updates and/or adds the available accounts and
     * bank connections for the authorized user, which are importable without user interaction.
     */
    getSynchronizeBankConnection: async (finapiAccessAccountIds) => {
        const res = await JwtAPI.request('post', '1', '/finapi/dataSources/bankConnections/synchronization', { finapiAccessAccountIds, ibans: [], bearerToken: JwtAPI.getFinAPIToken() });
        return res;
    },

    /*
     * Create a case - https://docs.finapi.io/#get-/cases/-caseId-/dataSources
     *
     * The service creates a new case file, i.e. an information container where the imported and analysed data of the authorized user is stored.
     * The case defines the timeframe for the report results within it, by setting the optional maxDaysForCase parameter. The parameter sets
     * the inclusive range (in number of days) for which the transactions will be analysed, going back from the case file creation date.
     *
     * Both accountIds and dataSourceIds can be used as data sources for the case creation. The data sources can be used as a container for multiple accounts.
     * Note: dataSourceIds will be resolved internally to accountIds to avoid changes in the report if the user adds or removes new accounts on the data source.
     * Note: A case file can contain maximum one Schufa Credit Check Data Source.
     * Note: maxDaysForCase parameter does not set any limitations for the data, retrieved based on the Schufa Credit Check Data Sources.
     */
    createCase: async (dataSourceIds, maxDaysForCase, withTransactions) => {
        const data = {
            dataSourceIds,
            maxDaysForCase: maxDaysForCase || 730,
            withTransactions: !!withTransactions,
            bearerToken: JwtAPI.getFinAPIToken(),
        };
        const res = await JwtAPI.request('post', '1', '/finapi/cases', data);
        return res;
    },

    /*
     * Create a contracts report for insurances - https://docs.finapi.io/#post-/cases/-caseId-/reports/contracts/insurance
     *
     * The service creates a Contracts Report for insurances based on the data stored in the case file. The transactions
     * which are used for the report creation are defined by the dataSourceIds inside the case file and maxDaysForCase parameter.
     * The parameter sets the inclusive range (in number of days) for which the transactions will be analysed, going back from
     * the case file creation date.
     *
     * If there is a need for report to be re-generated automatically in particular time periods without additional user interaction,
     * the interval and intervalPeriod fields need to be specified. Such a report will be considered continuous and will have
     * associated with it child reports, created automatically inside of the same case file.
     *
     * The result returned by the service is the UUID of the report created.
     *
     * Contracts Reports for insurances is aimed at returning the information about the insurance-related contractual relationships,
     * that the authorized user has in the analyzed time span together with the transactions associated with these contractual relationships.
     *
     * Thus, report will contain the transactions with the following labels: DISABILITYINSURANCE, LIABILITYINSURANCE, LIFEINSURANCE, CAREINSURANCE,
     * LEGALINSURANCE, ACCIDENTINSURANCE, TRAVELINSURANCE, SUPPLEMENTARYHEALTHINSURANCE, SUPPLEMENTARYDENTALINSURANCE, HOMECONTENTINSURANCE,
     * HOMEINSURANCE, PRIVATEPENSIONINSURANCE, ENDOWMENTINSURANCE, CARINSURANCE, HEALTHINSURANCE, INSURANCE, PETLIABILITYINSURANCE, PETHEALTHINSURANCE, PETINSURANCE.
     *
     * Note: dataSourceIds will be resolved internally to accountIds to avoid changes in the report if the user adds or removes new accounts on the data source.
     * Note: In case the report is considered continuous, the time span of transactions, that will be analysed for child reports is defined by interval and
     * intervalPeriod , not by maxDaysForCase parameter.
     * Note: A case file can contain maximum one Contracts Report for Insurances (child reports are not taken into account for this count).
     * Note: One report may have multiple intervals in place (for example in terms of both weeks and months). All the intervals will be applied to a report
     * independently of each other, thus, meaning that if the intervals overlap, the same report will be generated multiple times and multiple notifications
     * will be sent to the customer.
     */
    createInsuranceReport: async (caseId) => {
        const res = await JwtAPI.request('post', '1', '/finapi/cases/' + caseId + '/reports/contracts/insurance', { bearerToken: JwtAPI.getFinAPIToken() });
        return res;
    },

    /*
     * Get a contracts report for insurances - https://docs.finapi.io/#get-/cases/-caseId-/reports/contracts/insurance
     *
     * The service returns the Contracts Report for insurances created in the given case file. The report contains the information
     * about the insurance-related contractual relationships, that the authorized user has in the analyzed time span together with
     * the transactions associated with these contractual relationships. The transactions that the report contains are defined by
     * the dataSourceIds inside the case file and maxDaysForCase parameter.
     */
    getInsuranceReport: async (caseId) => {
        const params = {
            withTransactions: false,
            showOnlyActiveContracts: false,
            bearerToken: JwtAPI.getFinAPIToken(),
        };
        const res = await JwtAPI.request('get', '1', '/finapi/cases/' + caseId + '/reports/contracts/insurance', null, params);
        return res;
    },

    /*
     * Get the status of a data source - https://docs.finapi.io/#get-/dataSources/-dataSourceId-/status
     *
     * The service returns the status of the created data source, that can be either IN_PROGRESS, SUCCESSFUL, FAILED. The code returned as a part
     * of the response body provides the reason why the data source refers to this or that status.
     *
     * The service updates the given data source. If the consent of the user is no longer valid on the bank's side, then a Strong Customer Authentication
     * will be required for updating the bank data.
     *
     * Both accountIds and dataSourceIds can be used as dataSourceId for the bank connection update. In case the accountId is used as a dataSourceId
     * request parameter, the data source (bank connection), to which this account belongs will be updated.
     */
    getDatasourceStatus: async (dataSourceId) => {
        const res = await JwtAPI.request('post', '1', '/finapi/dataSources/' + dataSourceId + '/status', null, { bearerToken: JwtAPI.getFinAPIToken() }, false, 2500);
        return res;
    },
};

export default API;
