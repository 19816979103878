const privacy = [
    { type: 'secTitle', text: '02 Datenschutzrichtlinien'},
    { type: 'textTitl', text: 'ihn/sie in seinen/ihren Versicherungsangelegenheiten wie folgt zu vertreten bzw. für ihn/sie tätig zu werden:'},
    { type: 'textTitl', text: '1. Abgabe und Entgegennahme sämtlicher Willenserklärungen zum Zweck des Abschlusses und der Änderung sowie zur Beendigung von Versicherungsverträgen;'},
    { type: 'textTitl', text: '2. Abgabe von Wissenserklärungen (z. B. Anzeigen zur Erfüllung von Obliegenheiten etc.) gegenüber den Versicherern und sonstigen Dritten; '},
    { type: 'textTitl', text: '3. Geltendmachung von Leistungsansprüchen im Rahmen der Schadenbearbeitung für den/die Vollmachtgeber(in) gegenüber den Versicherern; '},
    { type: 'textTitl', text: '4. Vertretung des/der Vollmachtgebers/Vollmachtgeberin bei der Korrespondenz mit Versicherern sowie Behörden durch den Versicherungsmakler, über den sämtliche Korrespondenz zu führen ist;'},
    { type: 'textTitl', text: '5. Entgegennahme von Leistungen (Versicherungsleistungen, Entschädigungszahlungen) von den Versicherern im Rahmen der Schadenbearbeitung für die Vollmachtgeberin sowie von Beitragserstattungen durch die Versicherer; '},
    { type: 'textSubT', text: '6. Erteilung von Untervollmachten an andere Untervermittler/Untermakler oder sonstige Dritte, welche für folgende Unternehmen als ausdrücklich genehmigt gilt:'},
    { type: 'textTitl', text: 'blau direkt GmbH & Co.KG, Kaninchenborn 31, 23560 Lübeck'},
    { type: 'textSubT', text: 'Fonds Finanz Maklerservice GmbH, Riesstr. 25, 80992 München'},
    { type: 'textTitl', text: '7. Der Versicherungsmakler und die unter Ziffer 6 aufgeführten Unternehmen sind von den Beschränkungen des § 181 BGB befreit. '}
];

export default privacy;
