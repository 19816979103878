/**
 * Created by max on 2/16/22.
 */

const de_DE = {
    testing: {
        tHTML: '{b}boldText{/b}{u}underlineText{/u}',
    },
    errors: {
        customerNotExisted: 'Die Kundendaten sind nicht vorhanden, bitte führen Sie den Onboarding-Prozess erneut durch',
        firstSignIsEmpty: 'Erstes Zeichen ist leer, bitte starten Sie den Vorgang von vorne',
        secondSignIsEmpty: 'Zweites Zeichen ist leer, bitte starten Sie den Vorgang von vorne',
    },
    serverErrorIds: {
        email_is_already_confirmed: 'Es besteht bereits ein Account für diese E-Mail Adresse. Bitte gehe zum Login und logge Dich damit ein.',
        otp_is_already_sent: 'OTP-Code wurde bereits gesendet',
        phone_is_not_confirmed: 'Telefon ist nicht bestätigt',
        email_is_not_confirmed: 'E-Mail ist nicht bestätigt',
        credentials_not_right: 'Anmeldedaten stimmen nicht',
        unexpected_error: 'Leider ist ein Fehler bei der Datenübermittlung aufgetreten. Wir haben bereits den Fehler gemeldet. Bitte versuche es später noch einmal.',
    },
    codeResponsesAPI: {
        statusTimeout: 'Leider ist ein Fehler bei der Datenübermittlung aufgetreten. Wir haben bereits den Fehler gemeldet. Bitte versuche es später noch einmal.',
        statusNotAuthorize: 'Bitte log dich erneut ein. Deine aktive Session ist abgelaufen',
        statusBadRequest: 'Ein unbekannter Fehler ist aufgetreten. Wir haben bereits unsere Technik informiert und beheben den Fehler zeitnah.',
        statusInternalServerError: 'Leider ist ein Fehler bei der Datenübermittlung aufgetreten. Wir haben bereits den Fehler gemeldet. Bitte versuche es später noch einmal.',
    },
    common: {
        bottomImpressum: 'Impressum',
        bottomInfo: 'Erstinformation',
        bottomPrivacy: 'Datenschutz',
        expertTitle: 'DER EXPERTE',
        textSearch: 'Suchen',
        dropzoneTitle: 'vertragsDokumente hinzufügen (optional)',
        dropzoneText: 'Dokumente hier ablegen/hochladen oder Foto Aufnehmen',
        dropzoneBtn: 'Datei auswählen',
        ok: 'Ok',
        btnRegister: 'Registrieren',
    },
    elements: {
        providerTitle: 'ANBIETER',
        yes: 'Ja',
        no: 'Nein',
        youtubeText: 'Verträge importieren - was steckt dahinter?',
        textAddProvider: 'Logo\nfolgt',
        popupYoutubeClose: 'Verstanden',
        searchPlaceholder: 'suchen',
        asc: 'Product: Asc',
        desc: 'Product: Desc',
    },
    finApi: {
        noAccountIds: 'There is an error - no accounts ids',
        noBankConnections: 'No bank connections',
        noDataSourceIds: 'There is an error - no datasource ids',
        noContracts: 'There is an error - no contracts object in response',
    },
    validations: {
        phoneNotOwnPhone: 'Du kannst dir selbst keine Einladung senden.',
        validationPhoneNumber: "Bitte gebe eine gültige Telefonnummer ein",
        validationEmail: "Bitte gebe eine gültige E-Mail Adresse ein.",
        validationName: "Bitte gebe deinen Namen ein.",
        validationProviderEmpty: "Bitte wähle zuerst deinen Anbieter.",
        validationCustomProviderEmpty: "Bitte wähle zuerst Anbieter Manuell Eintragen.",
        validationSelectAllContracts: "Wähle zuerst einen Vertrag, um Cashback aktivieren zu können.",
        validationAddSignature: "Bitte unterschreibe zuerst, um fortzufahren.",
        validationAppoitmentDate: "Du kannst kein vergangenes Datum auswählen. Versuche es erneut.",
        validationJob: "Hier sind nur Buchstaben erlaubt.",
        validationNationality: "Bitte hier nur Buchstaben eingeben. Dann werden dir Nationalitäten zur Auswahl gestellt.",
        validationBDEmpty: "Bitte gib dein Geburtsdatum ein.",
        validationBD18: "Du musst mindestens 18 Jahre alt sein, um unseren Maklerauftrag nutzen zu können.",
        validationBD90: "Das zulässige höchstalter ist 90 Jahre.",
        validationEmailEmpty: "Die E-Mail Adresse ist ein Pflichtfeld.",
        validationEmailNotValid: "Bitte gib eine gültige E-Mail Adresse ein.",
        validationPhoneNotValid: "Bitte gib eine gültige Telefonnummer ein.",
        validationPhoneNotZeroStart: 'Deine Mobilfunknummer kann nicht mit 0 beginnen, da du bereits die Ländervorwahl nutzt. Lösche bitte die 0.',
        validationPhoneStart7: "Deine Mobilnummer muss mit 7 beginnen.",
        validationPhoneLength9: "Deine Mobilnummer muss genau 9 Ziffern beinhalten.",
        validationPhoneStart1: "Deine Mobilfunknummer muss mit 1 beginnen.",
        validationPhoneLength5: "Deine Mobilfunknummer muss mindestens 8 Ziffern beinhalten.",
        validationPhoneStart6: "Deine Mobilfunknummer muss mit 6 beginnen.",
        validationPhoneLength12: "Deine Mobilfunknummer darf maximal 12 Ziffern beinhalten.",
        validationPhoneStart3: "Deine Mobilfunknummer muss mit 3 beginnen.",
        validationPhoneLength10: "Deine Mobilnummer muss genau 10 Ziffern beinhalten.",
        validationPhoneLength8: "Deine Mobilfunknummer muss genau 8 Ziffern beinhalten.",
        validationPasswordEmpty: "Dein Passwort ist eine Pflichtangabe.",
        validationPasswordNotValid: "Dein Passwort muss mindestens 8 Zeichen, 1 Grossbuchstaben, 1 Kleinbuchstaben, 1 Zahl oder ein Sonderzeichen haben.",
        validationPasswordConfirmEmpty: "Passwort wiederholen ist eine Pflichtangabe.",
        validationPasswordConfirmNotValid: "Beide Passwörter müssen identisch sein.",
        validationFreebeeCodeLength10: "Dein Freischalt-Code muss 10 Zeichen beinhalten.",
        validationIBANNotValid: "Bitte gib eine gültige IBAN Nummer eingeben.",
        validationFirstNameEmpty: "Dein Vorname ist eine Pflichtangabe.",
        validationFirstNameLength: "Dein Name muss länger als 1 Buchstabe sein.",
        validationFirstNameNotNumbers: "Dein Name darf keine Nummer beinhalten.",
        validationLastNameEmpty: "Bitte gib deinen Nachnamen ein.",
        validationLastNameLength: "Dein Name muss länger als 1 Buchstabe sein.",
        validationLastNameNotNumbers: "Ein Name darf nicht mit einer Nummer beginnen.",
        validationPostcodeLength4: "Die Postleitzahl muss 4 Ziffern beinhalten.",
        validationPostcodeLength5: "Die Postleitzahl muss 5 Ziffern beinhalten.",
        validationPriceEmpty: "Beitrag ist ein Pflichtfeld.",
        validationPriceNotValid: "Bitte einen gültigen Beitrag bzw. eine Rate eingeben. Der Beitrag kann nur eine Zahl sein mit Angabe von Komma.",
        validationYearlySpendingEmpty: "Verbrauch im letzten Jahr ist ein Pflichtfeld.",
        validationYearlySpendingNotValid: "Bitte einen gültigen 'Verbrauch im letzten Jahr' bzw. eine Rate eingeben. Der 'Verbrauch im letzten Jahr' kann nur eine Zahl sein mit Angabe von Komma.",
        validationYearlySpendingCostEmpty: "Kosten im letzten Jahr ist ein Pflichtfeld.",
        validationYearlySpendingCostNotValid: "Bitte einen gültigen 'Kosten im letzten Jahr' bzw. eine Rate eingeben. Der 'Kosten im letzten Jahr' kann nur eine Zahl sein mit Angabe von Komma.",
        validationStartDate: "Der Vertragsbeginn darf nicht in der Zukunft liegen.",
        validationStartDateEmpty: "Der Vertragsbeginn darf nicht leer sein",
        validationEndDate: "Das Vertragsende darf nicht in der Vergangenheit liegen.",
        validationPaymentMethodRequired: "Die Zahlungsart ist ein Pflichtfeld.",
        validationAddressEmpty: "Strasse und Hausnummer ist ein Pflichtfeld.",
        validationAddressWithoutNumberEmpty: "Strasse ist ein Pflichtfeld.",
        validationHouseNumberEmpty: "Hausnummer ist ein Pflichtfeld.",
        validationPostcodeEmpty: "Postleitzahl ist ein Pflichtfeld.",
        validationCityEmpty: "Ort ist ein Pflichtfeld.",
        validationPhoneEmpty: "Mobilfunknummer ist ein Pflichtfeld.",
        validationCityNotNumbers: "Ein Ort darf nicht mit einer Nummer beginnen.",
        validationAddressContainNumbers: "Bitte gib eine aktuelle Strasse und Hausnummer ein.",
        validationHouseNumberContainNumbers: "Bitte gib eine aktuelle Hausnummer ein.",
        validationErrorPhoneUserExisted: "Ein Account mit der von dir angegebenen Mobilfunknummer existiert bereits. Gehe zurück zum Login und melde dich mit deinem Account an.",
        validationPaypalEmailEmpty: 'Die Paypal E-Mail Adresse ist ein Pflichtfeld.',
        validationPaypalEmailNotValid: 'Bitte eine gültige Paypal E-Mail Adresse eingeben.',
        validationNumberEmpty: "Vertragsnummer ist ein Pflichtfeld.",
        validationSalutationEmpty: "Anrede ist ein Pflichtfeld.",
    },
    header: {
        menuTopChat: 'CHAT',
        menuTopDashboard: 'DASHBOARD',
        menuTopContracts: 'VERTRAGSORDNER',
        menuTopProfile: 'PROFILE',
        menuTopSupport: 'SUPPORT & SERVICE',
    },
    slideMenu: {
        contracts: 'VERTRÄGE',
        profile: 'Profil',
        impressum: 'Impressum',
        logout: 'Ausloggen',
    },
    //-----
    //LISTS
    salutation: { mr: 'Herr', mrs: 'Frau' },
    //-----
    //-----
    //PAGES
    //-----
    termsPage: {
        h1: 'In wenigen Schritten zu deinem kostenfreien digitalen Vertragsordner.',
        title1: 'VERTRÄGE HINZUFÜGEN',
        text1: 'Füge im nächsten Schritt deine Verträge hinzu. Importiere diese entweder automatisch aus deinen Bankumsätzen oder erfasse die Vertragsdaten selbstständig.',
        title2: 'CASHBACK AKTIVIEREN',
        text2: 'Aktiviere Cashback für deine Verträge und beauftrage uns als deinen zukünftigen Finanzberater. Dadurch übernehmen wir für dich den Support deiner Verträge.',
        title3: 'ACCOUNT AKTIVIEREN',
        text3: 'Aktiviere deinen kostenfreien Zugang. So hast du jederzeit online Zugriff auf deinen Vertragsordner.',
        checkboxText1: 'Ich akzeptiere die',
        checkboxLink1: 'AGB',
        checkboxText2: 'Ich akzeptiere die',
        checkboxLink2: 'Datenschutzrichtlinien',
        checkboxText3: 'Ich akzeptiere die',
        checkboxLink3: 'Erstinformation',
        btnNext: 'Weiter',
    },
    termsPopup: {
        termsLink: '01 Allgemeine Geschäftsbedingungen (AGB)',
        privacyLink: '02 Datenschutzrichtlinien',
        infoLink: '03 Erstinformation',
        btnAgree: 'schließen',
    },
    onboardingContractAddPage: {
        h1: 'Wie möchtest du deine Verträge importieren?',
        expertMsg: 'Wir möchten dir die Erfassung deiner Verträge so einfach wie möglich machen. Du kannst deine Verträge neben der manuellen Eingabe auch automatisch importieren lassen.',
        finapiExplanation: '(Dieser Service wird von unserem Partner fin API bereitgestellt. Starte das Video für mehr Informationen.)',
        finapiError: 'Deine Anfrage konnte nicht übermittelt werden. Bitte klicke erneut auf den Button. Besteht der Fehler weiterhin, bitten wir dich deine Verträge manuell zu erfassen.',
        btnFinApi: 'AUS BANKUMSÄTZEN IMPORTIEREN - in 30 Sekunden',
        btnManual: 'Vertragsdaten selbst erfassen - IN 5 Minuten',
        youtubeTitle: 'Starte das Video, um mehr zu erfahren.',
    },
    onboardingContractListPage: {
        expertMsg: 'In der Liste findest du die häufigsten Versicherungen. Wähle alle aus, die du hast, damit sie dir in der App angezeigt werden. Du kannst auch später in deinem Account weitere Verträge hinzufügen.',
        expertMsgFinapi: 'In der Übersicht findest du die Verträge, die wir aus deinen Bankumsätzen identifizieren konnten. Bitte prüfe die Informationen und korrigiere diese ggf. Wähle im Anschluss die Verträge aus, für die du uns als Finanzberater aktivieren möchtest. Du kannst später in deinem Account weitere Verträge hinzufügen.',
        btnShowListProducts: 'Weitere Versicherungen anzeigen',
        btnEditLink: 'Korrigieren',
        btnEditRedLink: 'Korrigieren - Informationen fehlen',
        textSelectBroker: 'Cashback gewünscht',
        successAddedMsg: 'Sehr gut, deine Versicherung wurde hinzugefügt! Welche Versicherungen hast du noch?',
        errorNotSelected: 'Wähle bitte "Cashback gewünscht" für mindestens einen Vertrag.',
        btnNext: 'Weiter',
        errorNoProductInList: 'Es gibt kein Produkt in der Produktliste des Servers.',
        btnDeleteLink: 'Löschen',
        btnUnDeleteLink: 'Vertrag hinzufügen',
        contractNumber: 'Vertragsnr.',
        h1Products: 'Weitere Verträge hinzufügen',
    },
    onboardingProductsPage: {
        h1: 'Produktart auswählen',
        btnNext: 'Weiter',
        selectedProductText: 'Ausgewählte Produktart',
    },
    onboardingProvidersPage: {
        productTitle: 'Anbieter wählen für',
        customProvider: 'Mein anbieter fehlt in der liste',
        noProviders: 'Für die gewählte Vertragsart konnten wir keinen Anbieter finden. Bitte trage daher den Namen Deines Anbieters im unteren Feld ein.',
        btnNext: 'Weiter',
        selectedProviderText: 'Ausgewählte Anbieter',
        providerIsNotRelated: 'Du hast die Produktart gewechselt. Der zuvor ausgewählte Anbieter scheint nicht mehr richtig zu sein. Wähle bitte den passenden Anbieter aus.',
    },
    onboardingContractEditPage: {
        expertMsg: 'Vervollständige Deine Angaben.',
        number: 'vertragsnummer (optional)',
        btnNext: 'speichern',
    },
    onboardingContractListFinishPage: {
        h1: 'Starte das Video und erfahre mehr über deine Vorteile mit unserem Maklerauftrag.',
        expertMsg: 'Unterzeichne im nächsten Schritt den Maklerauftrag, um Cashback zu aktivieren.',
        headerText1: 'Der Maklerauftrag ist 100 % kostenfrei.',
        headerText2: 'Du kannst den Maklerauftrag jederzeit widerrufen.',
        headerText3: 'Deine Unterschrift ist rechtsgültig und wird von mehr als 140 Versicherern anerkannt.',
        successMsg: 'Sehr gut, deine Verträge wurden hinzugefügt!',
        btnNext: 'Weiter',
    },
    onboardingUserDataPage: {
        expertMsg: 'Bitte ergänze deine persönlichen Angaben. Deine Daten sind bei uns jederzeit sicher und werden ausschließlich nach gesetzlichen Vorgaben verarbeitet.',
        salutation: 'Anrede',
        firstName: 'vorname',
        lastName: 'nachname',
        bd: 'geburtsdatum',
        address: 'STRASSE UND HAUSNUMMER',
        postcode: 'plz',
        city: 'ort',
        btnNext: 'Weiter',
        email: 'e-mail adresse',
        emailDesc: 'An diese E-Mail Adresse bekommst du eine Bestätigungsmail. Wir senden keine Newsletter.',
        phoneCode: 'vorwahl',
        phoneNumber: 'mobilnummer',
        phoneNumberDesc: 'Mit dieser Mobilnummer kannst du dich zukünftig in deinem Account einloggen.',
        addressCompany: 'STRASSE UND HAUSNUMMER',
        postcodeCompany: 'plz',
        cityCompany: 'ort',
        h1: 'Angaben gesetzlicher Vertreter',
        companyName: 'firmenname',
        position: 'position',
    },
    onboardingSignBrokerPage: {
        h1: 'Maklerauftrag unterschreiben - 100% kostenfrei.',
        expertMsg: 'Du beauftragst uns als deinen Finanzberater für deine gewählten Verträge. Unser Service ist für dich dauerhaft kostenfrei und kann jederzeit widerrufen werden. ',
        textInfo1: '"Ich bestätige, dass ich die ',
        textInfo2: ', den ',
        linkInfo1: 'Erstinformation',
        linkInfo2: 'Maklerauftrag',
        textInfo3: ' einschl. der Information gem. § 60 Abs. 1 Satz 2 und Abs. 2 VVG bzgl. der eingeschränkten Versicherer- und Vertragsauswahl heruntergeladen und gelesen habe und akzeptiere diese mit meiner Unterschrift."',
        preNameText: 'Unterschrift - ',
        preNameCompanyText: ', vertreten durch:',
        text1: 'Deine Unterschrift ist rechtsgültig und wird von mehr als 140 Versicherern anerkannt.',
        text2: 'Der Maklervertrag ist 100 % kostenfrei.',
        text3: 'Du kannst den Maklervertrag jederzeit widerrufen.',
        btnNext: 'Weiter',
        agreeText: 'Ich möchte Bancassurance zukünftig als Finanzberater für meine gewählten Verträge nutzen und ',
        agreeTextLink: 'habe den Maklerauftrag gelesen.',
        healthText: 'Einwilligung in die Verarbeitung von Gesundheitsdaten',
        marketingText: 'Ich willige mit meiner folgenden Unterschrift in die Erhebung, Verarbeitung und Nutzung meiner personenbezogenen Daten (per E-Mail, Fax, Telefon und SMS) zu meiner Information ein, wie im Maklerauftrag unter Punkt 12 beschrieben.',
        companyText: 'Diese Einwilligung kann jederzeit ohne Angabe von Gründen – jedoch nur für die Zukunft – widerrufen werden. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Datenverarbeitung bleibt davon unberührt. Die Rechte auf Auskunft, Berichtigung, Löschung oder Sperrung der personenbezogenen Daten können jederzeit gegenüber dem Makler sowie den zuvor genannten Stellen geltend gemacht werden.',
    },
    onboardingSignPrivacyPage: {
        h1: 'Datenschutzerklärung unterschreiben.',
        expertMsg: 'Die Sicherheit deiner Daten steht bei uns an erster Stelle. Wir verarbeiten deine Daten nach ISO zertifiziertem Sicherheitsstandard. All deine Daten, die wir über dich speichern kannst du jederzeit bei uns abfragen.',
        linkText: 'Datenschutzerklärung lesen',
        preNameText: 'Unterschrift - ',
        preNameCompanyText: ', vertreten durch:',
        text1: 'Die Sicherheit deiner Daten steht bei uns an erster Stelle. Wir verarbeiten deine Daten nach ISO zertifiziertem Sicherheitsstandard. All deine Daten, die wir über dich speichern kannst du jederzeit bei uns abfragen.',
        text2: 'Diese Einwilligung kann jederzeit ohne Angabe von Gründen – jedoch nur für die Zukunft – widerrufen werden. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Datenverarbeitung bleibt davon unberührt. Die Rechte auf Auskunft, Berichtigung, Löschung oder Sperrung der personenbezogenen Daten können jederzeit gegenüber dem Makler sowie den zuvor genannten Stellen geltend gemacht werden.',
        btnNext: 'Weiter',
        contactText: 'Kontaktaufnahme per E-Mail, Telefon, Fax, Messenger-Dienste, Social Media und SMS zu Werbe- und Marketingzwecken',
        newServiceText: 'Vorstellung weitere Produkte und/oder Dienstleistungen per E-Mail, Telefon, Fax, Messenger-Dienste, Social Media und SMS',
        marketingText: 'Datenauswertung zu Marketingzwecken',
        youtubeTitle: 'Starte das Video und erfahre mehr über deine Vorteile mit unserem Maklerauftrag.',
    },
    onboardingBrokerSuccessPage: {
        expertTitle: 'Sehr gut! Deine Vertragsdaten wurden erfolgreich importiert.',
        expertMsg: 'Sehr gut, Dein Maklerauftrag wurde aktiviert! Deinen Maklerauftrag und Deine Verträge findest Du zukünftig in Deinem Account. Lass uns gemeinsam Deinen Zugang aktivieren. Dadurch kannst Du jederzeit auf Deinen Digitalen Vertragsordner zugreifen und wir helfen Dir bei Fragen.',
        expertMsgCompany: 'Sehr gut, dein Maklerauftrag wurde aktiviert! Dein Maklerauftrag und deine Verträge findest du zukünftig in deinem Account. Lass uns gemeinsam deinen Zugang aktivieren. Dadurch kannst Du jederzeit auf Deinen Digitalen Vertragsordner zugreifen und wir helfen Dir bei Fragen.',
        btnTitle: 'jetzt account aktivieren',
        btnNew: 'WEITERE VERTRÄGE IMPORTIEREN',
        successMsg: 'Hervorragend! Dein Maklerauftrag und Cashback wurde aktiviert.',
    },
    onboardingFinAPIImportPage: {
        h1Complete: 'Bitte warten - Deine Verträge werden ermittelt!',
        textComplete: 'Danke für Dein Vertrauen. Aktuell analysiert unsere künstliche Intelligenz Deine Daten und ermittelt Deine bestehenden Verträge für Dich. Die Analyse kann je nach Umfang der Informationen 0,5 bis 1 Minute in Anspruch nehmen. Du wirst im Anschluss automatisch weitergeleitet.',
        btnNext: 'Weiter',
    },
    onboardingFinAPIErrorPage: {
        expertTitle: 'Vertragserkennung aktuell nicht möglich.',
        expertMsg: 'Es ist ein interner Fehler aufgetreten. Wir arbeiten bereits an einer Lösung. Alternativ kannst du deine Vertragsdaten selbst erfassen.',
        btnTitle: 'Vertragsdaten selbst erfassen',
    },
    onboardingFinAPIRestartPage: {
        expertTitle: 'Die Sitzung ist abgelaufen.',
        expertMsg: 'Deine Sitzung ist leider abgelaufen. Lade die Seite neu und starte von vorne, um deine Vertragsdaten zu importieren.',
        btnTitle: 'Seite neu laden',
    },
    signupStepOnePage: {
        h1: 'Prüfe Deine Angaben.',
        phoneCode: 'vorwahl',
        phoneNumber: 'mobilnummer',
        phoneNumberDesc: 'Du erhältst im nächsten Schritt einen SMS Code.',
        email: 'e-mail adresse',
        emailDesc: 'Über die E-Mail Adresse kannst du dein Account final freischalten und dein Passwort wählen.',
        expertMsg: 'Dein Maklerauftrag und deine Verträge findest du zukünftig in deinem Account. Lass uns gemeinsam deinen Zugang aktivieren. Überprüfe hierzu unten deine Angaben.',
        simpleTextBottomLiFirst: 'Jederzeit Zugriff auf deine aktuellen Vertragsdaten.',
        simpleTextBottomLiSecond: 'Hilfe im Schadensfall.',
        btnNext: 'Weiter',
    },
    signupStepTwoPage: {
        h1: 'Mobilnummer verifizieren.',
        expertMsg: 'Ich habe dir einen Code per SMS gesendet. Trage ihn bitte unten in das Feld ein. Die Zustellung einer SMS kann je nach Anbieter bis zu 3 Minuten dauern.',
        btnNext: 'Weiter',
        prefixBeforeTimerSmsVerif: 'DER SMS CODE LÄUFT AB IN:',
        textAfterTimerSmsVerif: 'MINUTEN',
        isExpiredText: 'Dein SMS Code ist abgelaufen. Bitte fordere einen neuen Code an oder überprüfe deine Mobilnummer',
        btnSendAgain: 'SMS Code erneut anfordern',
    },
    signupStepThreePage: {
        h1: 'E-Mail Adresse verifizieren.',
        expertMsg: 'Ich habe dir eine E-Mail gesendet! Bitte klicke den Link in der E-Mail, um ein Passwort für dein Account zu wählen.',
        titleLinkEmail: 'KLICKE AUF DEN LINK IN DER E-MAIL',
        descLinkEmail: 'Gehe bitte in dein E-Mail Postfach. Öffne die E-Mail und klicke auf den Link.',
        titleEmailLetter: 'KEINE E-MAIL ERHALTEN?',
        descEmailLetterPrivate: 'Überprüfe bitte deinen Spam Ordner, falls du keine E-Mail erhalten hast.',
        descEmailLetter1: 'Überprüfe bitte Deinen Spam Ordner, falls Du keine E-Mail erhalten hast. Falls dort ebenfalls keine E-Mail ist, ',
        descEmailLink: 'klicke bitte hier',
        descEmailLetter2: ', um eine weitere E-Mail zu senden.',
        titleCreatePassw: 'ERSTELLE EIN PASSWORT',
        descCreatePassw: 'Nachdem du auf den Link geklickt hast, wirst du auf eine Seite weitergeleitet. Dort kannst du nun ein Passwort für deinen Account festlegen.',
        titleViewContract: 'DEINE VERTRAGSÜBERSICHT',
        descViewContract: 'Logge dich mit deiner E-Mail Adresse und deinem Passwort ein, um deine Vertragsübersicht zu sehen.',
        btnNext: 'Ich habe keine E-Mail erhalten',
    },
    signupStepFourPage: {
        h1: 'Lege dein gewünschtes Passwort fest.',
        successMsg: 'Deine E-Mail Adresse wurde verifiziert!',
        password: 'Passwort',
        rePassword: 'Passwort wiederholen',
        phoneNumber: 'mobilnummer',
        phoneNumberDesc: 'Du erhältst im nächsten Schritt einen SMS Code.',
        email: 'E-Mail Adresse',
        btnNext: 'Account aktivieren',
        emailNotConfirmed: 'Es besteht bereits ein Account für diese E-Mail Adresse. Bitte gehe zum Login und logge Dich damit ein.',
    },
    signinPage: {
        h1: 'Einloggen',
        successMsg: 'Super dein Account wurde aktiviert. Du kannst dich einloggen.',
        password: 'Passwort',
        phoneCode: 'vorwahl',
        phoneNumber: 'mobilnummer',
        phoneNumberDesc: 'Du erhältst im nächsten Schritt einen SMS Code.',
        buttomLink: 'Passwort vergessen',
        email: 'E-Mail Adresse',
        btnNext: 'einloggen',
        buttomRegister: 'Registrieren',
    },
    secureDashboardPage: {
        titleHeader: 'Hallo {name}! Hier ist Deine Übersicht',
        tickets: 'Anfragen',
        contracts: 'Verträge',
        payments: 'Gesamt-ausgaben',
        qrText: 'Freunde einladen',
        titleTicket: 'Anfragen',
        btnTitle: 'neue anfrage',
        textTicket: 'Neue Anfragen werden in wenigen Minuten angezeigt.....',
    },
    secureContractsListPage: {
        h1: 'Vertragsübersicht',
        contractNumber: 'Vertragsnr',
        contractYearlyCost: 'Kosten jährlich',
        contractStatus: 'Vertragsstatus',
        contractDocuments: 'Dokumente',
        contractTariffs: 'TARIFBAUSTEINE',
        brokerStatus_active_with_payment: 'Makler aktiv',
        brokerStatus_active_without_fee: 'Makler nicht möglich',
        brokerStatus_check_broker: 'Makler in Bearbeitung',
        brokerStatus_deactivation_broker: 'Makler aktivieren',
        brokerStatus_on_work: 'Makler in Bearbeitung',
        brokerStatus_open: 'Makler aktivieren',
        brokerStatus_post_processing: 'Makler in Bearbeitung',
        brokerStatus_reject: 'Makler nicht möglich',
        btnNewContract: 'verträge hinzufügen',
        btnAddDocuments: 'hinzufügen',
        contractPopupTitle: 'Was bedeutet der Status: Vertragsimport nicht möglich?',
        contractPopupLeftBtnTitle: 'Abbrechen',
        contractPopupRightBtnTitle: 'Vergleichsangebot anfordern',
        contractPopupText1: 'In diesem Fall unterstützt dein Anbieter den Vertragsimport nicht und wir können für dich deine Vertragsdetails leider nicht bereitstellen.',
        contractPopupText2: 'Tipp: Gerne finden wir für dich einen alternativen Vertrag zum gleichen oder günstigeren Preis inklusive Cashback und Vertragsimport. Unser Angebot ist kostenlos und unverbindlich.',

        cashbackRedLink: 'Cashback aktivieren',
        cashbackRedText: 'Dir entgehen:',
        cashbackYellowLink: 'Vertragsimport nicht möglich',
        cashbackMoneyText: 'Dein Cashback',
        cashbackClockText1: 'Vertragsdaten werden importiert',
        cashbackClockText2: 'Cashback vorgemerkt:',
        cashbackTotal: 'Cashback vorgemerkt',
        cashbackTotalValue: 'Cashback ausgezahlt',

        cashbackRedPopupTitle: 'Cashback aktivieren',
        cashbackRedPopupLeftBtnTitle: 'Abbrechen',
        cashbackRedPopupRightBtnTitle: 'Bestätigen',
        cashbackRedPopupText: 'Bitte bestätige, dass Du den kostenfreien Maklerauftrag für diesen Vertrag aktivieren möchtest. Mit dem Maklerauftrag aktivierst Du ebenfalls kostenfrei Deinen Cashback. Die Berechnung des angezeigten Cashbacks bezieht sich immer auf drei Jahre. ',
        cashbackYellowPopupTitle: 'Was bedeutet der Status: Vertragsimport nicht möglich?',
        cashbackYellowPopupLeftBtnTitle: 'Abbrechen',
        cashbackYellowPopupRightBtnTitle: 'Angebot anfordern',
        cashbackYellowPopupText1: 'In diesem Fall unterstützt Dein Anbieter den Vertragsimport nicht und wir können für Dich Deine Vertragsdetails leider nicht bereitstellen.',
        cashbackYellowPopupTipp: 'Tipp:',
        cashbackYellowPopupText2: ' Gerne finden wir für Dich einen alternativen Vertrag zum gleichen oder günstigeren Preis inklusive Cashback und Vertragsimport. Unser Angebot ist kostenlos und unverbindlich.',
    },
    secureContractDetailPage: {
        contractNumber: 'Vertragsnr',
        contractYearlyCost: 'Kosten jährlich',
        contractStatus: 'Vertragsstatus',
        contractCashback: 'Cashback',
        btnDocuments: 'Dokumente ({numberDocuments})',
        btnSupport: 'Service & Support',
        btnCheckContract: 'Vertrag überprüfen lassen',
        titleDetail: 'Vertragsdetails',
        contractOwner: 'vertragsinhaber',
        contractTariff: 'Tarifbezeichnung',
        titlePrice: 'Beitrag',
        contractPaymentMethod: 'Zahlungsmethode',
        contractPrice: 'Beitrag bzw. Rate',
        titleDates: 'Laufzeit',
        contractStartDate: 'Beginn',
        contractEndDate: 'Ende',
        fieldNotes: 'Notizen',
        btnDelete: 'Vertrag löschen',
        contractUpdatedText: 'Der Vertrag wird aktualisiert',
        btnSave: 'Speichern',
        brokerStatus_active_with_payment: 'Cashback aktiv',
        brokerStatus_active_without_fee: 'Cashback aktiv',
        brokerStatus_check_broker: 'Cashback aktivieren',
        brokerStatus_deactivation_broker: 'Vertragsdaten werden importiert',
        brokerStatus_on_work: 'Cashback aktivieren',
        brokerStatus_open: 'Vertragsdaten werden importiert',
        brokerStatus_post_processing: 'Cashback aktivieren',
        brokerStatus_reject: 'Vertragsimport nicht möglich',
        cashbackText: 'Dein Cashback',
    },
    secureContractDocumentsPage: {
        contractNumber: 'Vertragsnr',
        contractYearlyCost: 'Kosten jährlich',
        contractStatus: 'Vertragsstatus',
        contractCashback: 'Cashback',
        btnAddDocument: 'Dokumente hinzufügen',
        brokerStatus_active_with_payment: 'Cashback aktiv',
        brokerStatus_active_without_fee: 'Cashback aktiv',
        brokerStatus_check_broker: 'Cashback aktivieren',
        brokerStatus_deactivation_broker: 'Vertragsdaten werden importiert',
        brokerStatus_on_work: 'Cashback aktivieren',
        brokerStatus_open: 'Vertragsdaten werden importiert',
        brokerStatus_post_processing: 'Cashback aktivieren',
        brokerStatus_reject: 'Vertragsimport nicht möglich',
        documentAdded : 'Dokument wurde hinzugefügt',
        documentDelete: 'Dokument wurde gelöscht',
        h4: 'Vertragsdokumente',
    },
    secureCustomerProfilePage: {
        h1: 'Profil',
        h1Company: 'Unternehmensprofil',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'nachname',
        birthdate: 'geburstdatum',
        titleCommunication: 'Kommunikation',
        email: 'e-mail adresse',
        emailNotConfirmed: 'Nicht verifiziert',
        emailNotConfirmedLink: 'Jetzt Verifizierungslink anfordern',
        phoneCode: 'Vorwahl',
        phoneNumber: 'Mobilnummer',
        titleAddress: 'Anschrift',
        street: 'Straße',
        postcode: 'postleitzahl',
        city: 'Ort',
        titleBroker: 'Maklerauftrag',
        contactPerson: 'Angaben gesetzlicher Vertreter',
        companyName: 'firmenname',
        dateActiveBroker: 'aktiv seit',
        btnShowBrokerPdf: 'Maklerauftrag lesen & herunterladen',
        btnShowPrivacyPdf: 'Datenschutzerklärung herunterladen',
        btnCancelBroker: 'Maklerauftrag kündigen',
    },
    referralShareQR: {
        h2: 'Freunde einladen',
        h3ImageQR: 'Dein persönlicher QR Code',
        h3ClipBoard: 'Dein persönlicher Einladungslink',
        textDescroption: 'Teile deine Begeisterung für feelix mit Anderen und erhalte zusätzliches Cashback.* Du kannst deinen persönlichen QR Code herunterladen und teilen oder du kopierst einfach deinen persönlichen Einladungslink und teilst diesen mit Anderen.',
        btnDownload: 'QR Code herunterladen',
        bottomNote: '*Um zusätzlich Cashback zu bekommen, muss dein Kontakt ebenfalls Cashback für mindestens einen Vertrag aktiviert haben.',
        btnCopyLink: 'Kopieren',
        alertMessage: 'Kopiert',
        btnCancel: 'ABBRECHEN',
    },
    confirmEmailPage: {
        expertTitle: 'DER EXPERTE',
        expertMsg: 'Sehr gut, du hast deine E-Mail Adresse erfolgreich verifiziert',
        btnTitle: 'Weiter',
    },
    authStartRegisterPage: {
        h1: 'Registrieren',
        text: 'Möchtest du den Account für dich als Privatperson oder für ein Unternehmen erstellen?',
        registerPrivateBtn: 'ich bin eine privatperson',
        registerCompanyBtn: 'ich bin ein unternehmen',
        registerLoginBtn: 'In bestehenden Account einloggen.',
    },
    authStartLoginPage: {
        h1: 'Einloggen',
        text: 'Möchtest du dich als Privatperson oder als ein Unternehmen einloggen?',
        registerPrivateBtn: 'ALS PRIVATPERSON EINLOGGEN',
        registerCompanyBtn: 'ALS UNTERNEHMEN EINLOGGEN',
        registerLoginBtn: 'Ich habe kein account - zur registrierung',
    },
    onboardingSignPowerAttorneyPage: {
        h1: 'Maklervollmacht unterschreiben',
        expertMsg: 'Du beauftragst uns als deinen Finanzmakler für deine gewählten Verträge. Unser Service ist für dich dauerhaft kostenfrei und kann jederzeit widerrufen werden.',
        text: 'Ich erteile Bancassurance die notwendige Maklervollmacht und habe die ',
        linkText: 'Maklervollmacht gelesen.',
        preNameText: 'Unterschrift - ',
        preNameCompanyText: ', vertreten durch:',
        btnNext: 'Weiter',
    },
    authForgotEmailSendPage: {
        h1: 'Gebe bitte deine E-Mail Adresse an.',
        email: 'E-Mail Adresse',
        btnNext: 'Weiter',
        errorEmailNotFound: 'Deine E-Mail Adresse wurde bisher nicht bestätigt. Bitte überprüfe Deinen E-Mail Account inkl. Spam-Ordner bzgl. unserer E-Mail mit dem Bestätigungslink und klicke den Link.',
    },
    authForgotEmailConfirmPage: {
        h1: 'Wir haben dir eine E-Mail mit einem Code gesendet. Trage ihn bitte unten ein und wähle ein neues sicheres Passwort.',
        code: 'Code aus der E-Mail eintragen',
        password: 'Neues passwort',
        rePassword: 'Passwort wiederholen',
        btnNext: 'Passwort speichern',
    },
};

export default de_DE;
