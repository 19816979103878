/**
 * Created by Max Gornostayev on 05/26/22
 *
 * this is a button that is shown on header in dashboard or other secure pages
 */

import IconArrowRightSmall from '../../assets/icon-arrow-small.svg';

export default function ButtonDashboard({ onClick, className, icon, titleNumber, titleText, isWhite, noRightMargin, arr }) {
    //on lick handler when click on the link
    const click = () => {
        if (onClick) {
            onClick();
        }
    };
    //prepare class name for container
    let clssName = 'button-dashboard';
    if (className) {
        clssName += ' ' + className;
    }
    if (isWhite) {
        clssName += ' white';
    }
    if (noRightMargin) {
        clssName += ' no-right-margin';
    }

    const renederItem = (icon, titleNumber, titleText, isShowArrow, index) => {
        return (
            <div className="button-dashboard-item" key={index}>
                {!!icon && <img src={icon} alt="icon" />}
                <div className="text">
                    {<div className="number">{titleNumber}</div>}
                    {!!titleText && (
                        <div className="row-spacebetween-end">
                            <div className="title">{titleText}</div>
                            {isShowArrow && (
                                <div className="click-icon">
                                    <img src={IconArrowRightSmall} alt="arrow" />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    if (!arr) {
        return (
            <div className={clssName} onClick={click} style={!onClick ? { cursor: 'default' } : {}}>
                {renederItem(icon, titleNumber, titleText, !!onClick, 0)}
            </div>
        );
    }

    return (
        <div className={clssName} onClick={click} style={!onClick ? { cursor: 'default' } : {}}>
            <div className="button-dashboard-values">{arr.map((item, index) => renederItem(item.icon, item.titleNumber, item.titleText, item.isShowArrow, index))}</div>
        </div>
    );
}
